import type { FC } from 'react'
import React, { useState, useEffect, useCallback } from 'react'
import styled from '@emotion/styled'
import { bp } from '@helloextend/customers-ui'
import { ConnectRequest } from '@extend-incredibot/types'
import { useTheme } from '@emotion/react'
import { useHistory } from 'react-router'
import { useIntl } from 'react-intl'
import { ModalController } from '@extend/zen'
import type { ContractsSearchIndividual } from '../../types/contract'
import { logEvent } from '../../analytics'
import { FileClaimLanguageOptionModal } from '../../components/file-claim-language-option-modal/file-claim-language-option-modal'
import CardMapper from './card-mapper'
import ContactMerchantModal from '../../components/contact-merchant-modal'

export type ContractListProps = {
  groupedContracts: Record<string, Array<ContractsSearchIndividual>>
}

const ContractList: FC<ContractListProps> = ({ groupedContracts }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isCustomerSupportModalOpen, setIsCustomerSupportModalOpen] = useState(false)
  const [isFilingClaim, setIsFilingClaim] = useState(false)
  const [connectData, setConnectData] = useState<Partial<ConnectRequest>>()
  const theme = useTheme()
  const { isExtend } = theme.merchantConfiguration

  const history = useHistory()
  const { locale } = useIntl()

  const handleModalOpen = (): void => {
    setIsModalOpen(true)
  }

  const handleModalClose = (): void => {
    setIsModalOpen(false)
  }

  const handleFileClaim = useCallback((): void => {
    if (!connectData?.contractId) return

    logEvent('My Plans - Contract Card - Clicks', 'File a Claim')
    history.push(!isExtend ? `/${locale}/my_claims/file-claim` : `/${locale}/kaley`, {
      contractId: connectData?.contractId,
      lineItemIds: connectData?.lineItemIds,
      shipmentId: connectData?.shipmentId,
    })
  }, [connectData, history, locale])

  const handleFileClaimClick = (
    handlerArgs: Partial<ConnectRequest> & {
      isMerchantOwnedIntake?: boolean
    },
  ): void => {
    setConnectData({
      contractId: handlerArgs.contractId,
      lineItemIds: handlerArgs.lineItemIds,
      shipmentId: handlerArgs.shipmentId,
    })
    if (handlerArgs.isMerchantOwnedIntake && handlerArgs.contractId === connectData?.contractId) {
      setIsCustomerSupportModalOpen(true)
    } else if (['fr-CA', 'en-CA'].includes(locale)) {
      handleModalOpen()
    } else {
      setIsFilingClaim(true)
    }
  }

  useEffect(() => {
    if (isFilingClaim && connectData?.contractId) {
      handleFileClaim()
      setIsFilingClaim(false)
    }
  }, [isFilingClaim, connectData, handleFileClaim])

  const selectedContract = Object.values(groupedContracts)
    .flat()
    .find((contract) => contract.id === connectData?.contractId)

  return (
    <ContractsWrapper data-cy="contract-list">
      {Object.entries(groupedContracts).map(([type, contracts]) => (
        <ContractGroupWrapper key={type}>
          {contracts.map((contract: ContractsSearchIndividual) => (
            <CardMapper
              key={contract.id}
              contract={contract}
              handleFileClaimClick={handleFileClaimClick}
            />
          ))}
        </ContractGroupWrapper>
      ))}
      <FileClaimLanguageOptionModal
        isModalOpen={isModalOpen}
        onDismiss={handleModalClose}
        onContinue={handleFileClaim}
      />
      <ModalController isOpen={isCustomerSupportModalOpen}>
        <ContactMerchantModal
          onDismissRequest={() => setIsCustomerSupportModalOpen(false)}
          storeId={selectedContract?.sellerId as string}
        />
      </ModalController>
    </ContractsWrapper>
  )
}

const ContractsWrapper = styled.div({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  gap: '24px',
  [bp.mobile]: {
    justifyContent: 'center',
  },
  [bp.desktop]: {
    justifyContent: 'flex-start',
  },
})

const ContractGroupWrapper = styled.div({
  display: 'flex',
  [bp.mobile]: {
    flexDirection: 'column',
    gap: '16px',
  },
  [bp.desktop]: {
    gap: '24px',
    flexDirection: 'column',
    flexWrap:  'nowrap',
  },
})

export { ContractList }
