export const formatDate = (timestamp: string, includeYear: boolean): string => {
    const options: Intl.DateTimeFormatOptions = {
        weekday: 'long',
        month: 'long',
        day: 'numeric',
        year: includeYear ? 'numeric' : undefined,
    }

    return new Date(timestamp).toLocaleDateString(undefined, options)
}

export const formatTime = (timestamp: string): string => {
    const options: Intl.DateTimeFormatOptions = {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
    }

    return new Date(timestamp).toLocaleTimeString(undefined, options)
}