import type {
  AuthFinishContact,
  AuthStartRequest,
} from '@helloextend/extend-api-rtk-query/src/my-extend/types'
import { formatPhoneNumberIso } from '@extend/client-helpers'
import type { Locale } from '../../types/localization'
import type { UserInfo } from './myextend-authentication'

export function buildPayload(
  userInfo: UserInfo,
  locale: Locale,
  isExtend: boolean,
  subdomainName: string
): AuthStartRequest | AuthFinishContact {
  const { email, phoneNumber } = userInfo

  if (email) return {
    email, ...(!isExtend && { brand: subdomainName })
  }
  if (phoneNumber) {
    const [, country] = locale.split('-')
    return { phoneNumber: formatPhoneNumberIso(phoneNumber, country), ...(!isExtend && { brand: subdomainName }) }
  }
  throw new Error('unexpected user info missing to build payload')
}
