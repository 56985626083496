import type { FC } from 'react'
import React from 'react'
import { ThemeProvider } from '@emotion/react'
import { BrowserRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import * as selectors from '../reducers/selectors'
import type { RootState } from '../reducers'
import { SubRouter } from './sub-router'
import type { RouterProps } from './types'
import { DevLinkProvider } from '../devlink'
import { getThemeByName, Loading } from '@extend/zen'
import {
  useGetMerchantBrandingConfigurationsQuery,
} from '@helloextend/extend-api-rtk-query'
import { extendConfiguration, buildMerchantConfiguration } from '../utils/configuration-builder'
import type { MerchantConfiguration } from '../types/merchant-configuration'
import { getSubDomainName, isExtendDomain } from './utils'

const Component: FC<RouterProps> = ({ isLoggedIn }) => {
  const subdomainName = getSubDomainName()
  let isExtend = isExtendDomain(subdomainName)

  const { data: merchantConfigurationResponse, isError, isLoading } = useGetMerchantBrandingConfigurationsQuery({
    subdomainName,
  }, { skip: isExtend })

  let merchantConfiguration: MerchantConfiguration
  if (isLoading) {
    return <Loading
      dotsSize="lg"
      heightPx={800}
    />
  }

  if (isError) {
    isExtend = true
  }
  if (!isExtend && merchantConfigurationResponse) {
    merchantConfiguration = buildMerchantConfiguration(merchantConfigurationResponse)
  }
  else {
    merchantConfiguration = extendConfiguration
  }

  return (
    <ThemeProvider theme={{ ...getThemeByName('consumers'), merchantConfiguration }} >
      <Helmet>
        <link rel='shortcut icon' type='image/x-icon' href={merchantConfiguration.brandingConfigurations.assetsUrls.favicon} />
      </Helmet>
      <DevLinkProvider>
        <BrowserRouter>
          <SubRouter isLoggedIn={isLoggedIn} />
        </BrowserRouter>
      </DevLinkProvider>
    </ThemeProvider>
  )
}

const ConnectedRouter = connect(
  (state: RootState): RouterProps => ({
    isLoggedIn: selectors.getIsLoggedIn(state),
  }),
)(Component)

export { Component, ConnectedRouter as Router }
