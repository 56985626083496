import type {
  ClaimStatus,
  Message,
  Prompt,
} from '@helloextend/extend-api-client'
import { MultiSelectPrompt } from '@extend-incredibot/types'
import { EXTEND_ENV } from '@helloextend/client-constants'
import { Slot } from '@helloextend/extend-api-client'
import { shortUid } from '@extend/client-helpers'
import { MessageType } from '@extend-conversations/types'
import { Reply } from '@extend-incredibot/types'
import type { BotMessage, UserMessage } from '../types/chat-message'
import { toTitleCase } from '../utils/string-utils'

const BOT_FAILURE_MESSAGE = 'Oops, something went wrong'

export function searchParams(param: string, value: string): boolean {
  if (EXTEND_ENV === 'production') {
    return false
  }

  const url = new URL(window.location.href)
  const urlParam = url.searchParams.get(param)
  return urlParam === value
}

export function getParam(param: string): string | null {
  if (EXTEND_ENV === 'production') {
    return null
  }

  const url = new URL(window.location.href)
  return url.searchParams.get(param)
}

function typingTimeout(delay: number): number {
  return searchParams('fast', 'true') ? 0 : delay
}

function formatBotMessages(messages: Message[]): BotMessage[] {
  const msDelayPerCharacter = 10
  const msDelayBeforeTyping = 500

  return messages.map((message) => ({
    ...message,
    bot: true,
    isTyping: true,
    initialPauseTimeout: typingTimeout(msDelayBeforeTyping),
    showTypingTimeout: typingTimeout(message.content.length * msDelayPerCharacter),
    key: shortUid(),
  }))
}

function formatUserMessage({
  content,
  slot,
  imageUrl,
}: {
  content: UserMessage['content']
  slot: Slot
  imageUrl?: string
}): UserMessage {
  return {
    slot,
    content,
    isTyping: false,
    bot: false,
    key: shortUid(),
    imageUrl,
  }
}

function isMultiselectPrompt(prompt: Prompt): boolean {
  return prompt.type === 'multiselect'
}

// TODO: remove once concierge is managed on backend
function filteredSlots(slots: Slot[], slot: Slot): Slot[] {
  if (slot === slots[slots.length - 1]) {
    return slots
  }
  return [...slots, slot]
}

function requestTimeout(message: BotMessage, timeout = 0): number {
  const { showTypingTimeout } = message
  if (timeout > showTypingTimeout) {
    return 0
  }
  return showTypingTimeout - timeout
}

function generateBotMessage(): BotMessage {
  return {
    content: '',
    key: shortUid(),
    isTyping: true,
    bot: true,
    initialPauseTimeout: 500,
    showTypingTimeout: 0,
    type: 'text',
  }
}

function generateBotFailureMessage(message: string): BotMessage {
  return { ...generateBotMessage(), content: message, isTyping: false }
}

function generateContactPageRedirectPrompt(): Prompt {
  return {
    slot: Slot.EndClaim,
    type: 'buttons',
    options: [
      {
        title: 'Support form, please',
        value: 'Support form, please',
        redirectUrl: '/contact',
      },
    ],
  }
}

function generateCardTitle(status: ClaimStatus): string {
  switch (status) {
    case 'review':
      return 'Claim In Review'
    default:
      return `Claim ${toTitleCase(status)}`
  }
}

export const getMessagesContent = (messages: Reply['messages']): string | undefined =>
  messages
    .reduce(
      (output: string, message: Reply['messages'][0]) =>
        message.type === MessageType.text ? `${output} ${message.content.trim()}` : output,
      '',
    )
    .trim()

export const getOptions = (reply: Reply): Array<MultiSelectPrompt['options'][0]> => {
  if (reply.prompt && 'options' in reply.prompt) {
    return reply.prompt.options || []
  }

  return []
}

export {
  isMultiselectPrompt,
  formatBotMessages,
  formatUserMessage,
  filteredSlots,
  requestTimeout,
  generateBotMessage,
  generateBotFailureMessage,
  generateCardTitle,
  generateContactPageRedirectPrompt,
  typingTimeout,
  BOT_FAILURE_MESSAGE,
}
