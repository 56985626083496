import { useTrackingQuery } from '@helloextend/extend-api-rtk-query'
import React, { useMemo } from 'react'
import { useLocation } from 'react-router'
import WismoContent from './components/wismo-content'
import { bp, ContentLayout } from '@helloextend/customers-ui'
import styled from '@emotion/styled'
import { TrackingRequest } from '@extend-myextend/types'
import { RouterError, SplashScreen } from '@helloextend/component-commons'

const Tracking = () => {
  const location = useLocation()
  const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search])
  const orderId = queryParams.get('orderId') || ''
  const trackingNumber = queryParams.get('trackingNumber') || ''
  const carrierCode: TrackingRequest['carrierCode'] = (queryParams.get('carrierCode') ||
    '') as TrackingRequest['carrierCode']

  const { data: trackingData, isLoading: isTrackingLoading } = useTrackingQuery(
    { trackingNumber, carrierCode, orderId },
    { skip: !trackingNumber || !carrierCode || !orderId },
  )

  if (isTrackingLoading) return <SplashScreen data-cy="splash-screen" />

  return trackingData ? (
    <ContentLayout>
      <TrackingContentWrapper>
        <WismoContent
          trackingData={trackingData}
          carrierCode={carrierCode}
          data-cy="wismo-content"
        />
      </TrackingContentWrapper>
    </ContentLayout>
  ) : (
    <RouterError data-cy="404-page" />
  )
}

const TrackingContentWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  alignItems: 'center',
  marginTop: 56,
  [bp.mobile]: {
    marginTop: 24,
  },
})

export { Tracking }
