import type { ShippingProtectionContract, ShippingProtectionProduct } from '../types/contract'

export type MappedTrackingData = {
  trackedProducts?: Array<{
    trackingInfo: ShippingProtectionContract['trackingInfo'][0]
    products: ShippingProtectionProduct[]
  }>
  untrackedProducts: ShippingProtectionProduct[]
}

/**
 * Due to trackingInfo[x].productsShipped containing referenceIds, we need to keep track of unique LineItemIds when building out tracking info
 */
export const mapTrackingData = (contract: ShippingProtectionContract): MappedTrackingData => {
  const { productsList, trackingInfo } = contract as {
    productsList: ShippingProtectionProduct[]
    trackingInfo: ShippingProtectionContract['trackingInfo']
  }
  if (!trackingInfo || !trackingInfo.length) {
    return { untrackedProducts: productsList }
  }

  const productMap = new Map<string, ShippingProtectionProduct[]>()
  const untrackedProductIds = new Set<string>()

  productsList.forEach((product) => {
    const { referenceId, lineItemId } = product as ShippingProtectionProduct
    if (!productMap.has(referenceId)) {
      productMap.set(referenceId, [])
    }
    productMap.get(referenceId)?.push(product)
    untrackedProductIds.add(lineItemId)
  })

  const trackedProducts: Array<{
    trackingInfo: ShippingProtectionContract['trackingInfo'][0]
    products: ShippingProtectionProduct[]
  }> = []

  trackingInfo.forEach((shipment) => {
    const productsForShipment = shipment.productsShipped.reduce((acc, productId) => {
      const products = productMap.get(productId) || []
      const product = products.shift() as ShippingProtectionProduct
      if (product) {
        acc.push(product)
        untrackedProductIds.delete(product.lineItemId)
      }
      return acc
    }, [] as ShippingProtectionProduct[])

    trackedProducts.push({
      trackingInfo: shipment,
      products: productsForShipment,
    })
  })

  const untrackedProducts = productsList.filter((product) =>
    untrackedProductIds.has(product.lineItemId),
  )

  return {
    trackedProducts,
    untrackedProducts,
  }
}
