import React, { FC, SyntheticEvent } from 'react'
import styled from '@emotion/styled'
import {
  Button,
  ButtonProps,
  ColorGroupKeys,
  MenuButtonItem,
  More,
  Popover,
  usePopover,
} from '@extend/zen'

export const CardMenuItems: FC<{
  color: ColorGroupKeys
  'data-cy': string
  menuItems: ButtonProps[]
  menuKey: string
}> = ({ color, 'data-cy': dataCy, menuKey, menuItems }) => {
  const { triggerRef, popoverRef, isPresent, toggle, triggerBoundingBox } =
    usePopover<HTMLButtonElement>({
      closeOnClickOutside: true,
    })

  return (
    <>
      <Button
        key={menuKey}
        color={color}
        emphasis="medium"
        icon={More}
        onClick={toggle}
        ref={triggerRef}
        isToggled={isPresent}
        data-cy={`${menuKey}-${dataCy}-more-button`}
      />
      <Popover ref={popoverRef} isPresent={isPresent} triggerBoundingBox={triggerBoundingBox}>
        <MenuItemsWrapper data-cy={`${dataCy}-items-wrapper`}>
          {menuItems.map((item, index) => {
            const listKey = `${menuKey}-${dataCy}-menu-button-item-${index}`
            return (
              <MenuButtonItem
                key={listKey}
                onClick={item.onClick as (event: SyntheticEvent<HTMLButtonElement>) => void}
                data-cy={listKey}
              >
                {item.text}
              </MenuButtonItem>
            )
          })}
        </MenuItemsWrapper>
      </Popover>
    </>
  )
}

const MenuItemsWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
  padding: '8px',
})
