import type { FC } from 'react'
import React, { useMemo } from 'react'
import { Accordion, bp } from '@helloextend/customers-ui'
import type { ShippingProtectionPlanDetailsFaqs } from '@helloextend/extend-api-client'
import { ShippingProtectionFaqAnswer } from '../../../../lib/assets'
import { Stack } from '@extend/zen'
import styled from '@emotion/styled'

export const presetQuestions = [
  'When does the shipping protection start and end?',
  'What if my purchase ships in multiple packages? Are they all covered?',
  'Is there a limit to what I can cover with shipping protection?',
  'What items are eligible for shipping protection?',
  'When do I file a claim?',
  'How do I file a claim?',
  'After I file a claim, what happens?',
  'Can I cancel my shipping protection?',
]

type FaqsProps = Partial<ShippingProtectionPlanDetailsFaqs>

const Faqs: FC<FaqsProps> = ({
  faqHeader,
  faq1,
  faq1Answer,
  faq2,
  faq2Answer,
  faq3,
  faq3Answer,
  faq4,
  faq4Answer,
  faq5,
  faq5Answer,
  faq6,
  faq6Answer,
  faq7,
  faq7Answer,
  faq8,
  faq8Answer,
}) => {
  const faqListTransform = useMemo(() => {
    return [
      [faq1, faq1Answer],
      [faq2, faq2Answer],
      [faq3, faq3Answer],
      [faq4, faq4Answer],
      [faq5, faq5Answer],
      [faq6, faq6Answer],
      [faq7, faq7Answer],
      [faq8, faq8Answer],
    ].map(([question, answer], idx) => {
      const fallbackQuestion = presetQuestions[idx]
      return [
        question || fallbackQuestion,
        answer || <ShippingProtectionFaqAnswer question={fallbackQuestion} />,
      ]
    }) as Array<[JSX.Element | string, JSX.Element]>
  }, [
    faq1,
    faq1Answer,
    faq2,
    faq2Answer,
    faq3,
    faq3Answer,
    faq4,
    faq4Answer,
    faq5,
    faq5Answer,
    faq6,
    faq6Answer,
    faq7,
    faq7Answer,
    faq8,
    faq8Answer,
  ])

  const spacing = 2
  return (
    <Stack spacing={spacing} align="center">
      <MainTitle>{faqHeader || 'Frequently Asked Questions'}</MainTitle>
      <Accordion list={faqListTransform} />
    </Stack>
  )
}

const MainTitle = styled.div({
  fontWeight: 600,
  [bp.mobile]: {
    fontSize: 28,
    lineHeight: '36px',
    textAlign: 'center',
  },
  [bp.desktop]: {
    fontSize: 36,
    lineHeight: '44px',
    textAlign: 'center',
  },
})

export { Faqs }
