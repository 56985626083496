import type { LineItemValidation, PrecheckResponse } from '@extend-services/adjudication-management'
import { ServiceOrder } from '@helloextend/extend-api-client'

export const isPrecheckWithLineItems = (
  precheck?: PrecheckResponse,
): precheck is { status: 'success'; lineItems: Record<string, LineItemValidation> } => {
  return precheck?.status === 'success' && 'lineItems' in precheck
}

export const isDepotRepair = (serviceOrder: ServiceOrder) => {
  return (
    serviceOrder.serviceType === 'repair_depot' ||
    serviceOrder.configurations?.repairFulfillmentMethod === 'repair_depot'
  )
}

export const isOnsiteRepair = (serviceOrder: ServiceOrder) => {
  return (
    serviceOrder.serviceType === 'repair_onsite' ||
    serviceOrder.configurations?.repairFulfillmentMethod === 'repair_onsite'
  )
}
