import type { FC } from 'react'
import React, { useMemo, useState } from 'react'
import { useLocation, useParams } from 'react-router'
import { useFlags } from 'launchdarkly-react-client-sdk'
import styled from '@emotion/styled'
import { SplashScreen } from '@helloextend/component-commons'
import { MAX_WIDTH, bp, customLogger } from '@extend/client-helpers'
import { ContentLayout } from '@helloextend/customers-ui'
import {
  useFetchPlanDetailsQuery,
  useFetchPlanQuery,
  useLazyGetContentQuery,
  getRTKQueryErrorMessage,
} from '@helloextend/extend-api-rtk-query'
import { useIntl } from 'react-intl'
import { ResourceErrorBoundary } from '../../components/resource-error-boundary'
import { PlanFeatures } from './plan-features/plan-features'
import { PlanCalculator } from './plan-calculator/plan-calculator'
import { ClaimSteps } from './claim-steps/claim-steps'
import { Faqs } from './faqs/faqs'
import { Testimonials } from './testimonials/testimonials'
import { Hero } from './hero'
import { FinePrintBanner } from './fine-print-banner'
import { LDFlag } from '../../constants/ld-flags'
import { usePlanDetailsData } from '../../hooks/use-plan-details-data'

export const DEFAULT_PLAN_ID = '10001-misc-elec-base-replace-1y'

const PlanDetails: FC = () => {
  /**
   * LaunchDarkly
   */
  const { [LDFlag.PlanDetailsCCSFetch]: FF_PLAN_DETAILS_CCS_FETCH } = useFlags()

  /**
   * param / query string extraction
   */
  const { id: planId = DEFAULT_PLAN_ID } = useParams<{ id: string; storeId: string }>()
  const { search } = useLocation()
  const query = useMemo(() => new URLSearchParams(search), [search])
  const storeId = query.get('storeId')
  const { locale } = useIntl()
  /**
   * Queries
   */

  // fetch plan
  const {
    data: plan,
    isFetching: isFetchingPlan,
    isLoading: isLoadingPlan,
    isError: isFetchPlanError,
    error: fetchPlanError,
  } = useFetchPlanQuery(planId)

  // fetch plan details
  const {
    data: planDetailsQueryData,
    isFetching: isFetchingPlanDetails,
    isLoading: isLoadingPlanDetails,
    isError: isFetchPlanDetailsError,
    error: fetchPlanDetailsError,
  } = useFetchPlanDetailsQuery(planId)

  // conditionally executed /content/offers query
  const [
    getContentFn,
    {
      isError: isGetContentError,
      isLoading: isLoadingContent,
      isFetching: isFetchingContent,
      data: contentQueryData,
      error: getContentError,
    },
  ] = useLazyGetContentQuery()

  const isLoadingPlanOrPlanDetails =
    isFetchingPlan || isFetchingPlanDetails || isLoadingPlan || isLoadingPlanDetails

  const isErroredPlanOrPlanDetails = isFetchPlanError || isFetchPlanDetailsError

  const { planDetails, isLoading, isErrored } = usePlanDetailsData({
    isErrored: isErroredPlanOrPlanDetails,
    isLoading: isLoadingPlanOrPlanDetails,
    storeId: storeId || '',
    plan,
    locale,
    isPlanDetailsCCSFetchingEnabled: FF_PLAN_DETAILS_CCS_FETCH,
    getContentFn,
    getContentState: {
      isError: isGetContentError,
      isLoading: isLoadingContent,
      isFetching: isFetchingContent,
      contentQueryData,
    },
    planDetailsQueryData,
  })

  // Error logging for requests
  const [hasLoggedError, setHasLoggedError] = useState(false)
  const queryError = getContentError || fetchPlanError || fetchPlanDetailsError
  if (queryError && !hasLoggedError) {
    const errorMessage = getRTKQueryErrorMessage(queryError)
    customLogger.warn(`[Err: Plan Details Page]: ${errorMessage}`, {
      isGetContentError,
      isFetchPlanError,
      isFetchPlanDetailsError,
      planId,
      storeId,
    })
    setHasLoggedError(true)
  }

  /**
   * Splash / loading / 404
   */
  if (isErrored || isLoading || !plan || !planDetails) {
    return (
      <ResourceErrorBoundary>
        <ContentLayout>
          <SplashScreen data-cy="splash-screen" />
        </ContentLayout>
      </ResourceErrorBoundary>
    )
  }

  return (
    <ContentLayout>
      <Main role="main">
        <Hero planDetails={planDetails} />
        <PlanCalculator plan={plan} planDetails={planDetails} />
        <PlanFeatures plan={plan} planDetails={planDetails} />
        <FinePrintBanner planId={planId} planDetails={planDetails} />
        <ClaimSteps planDetails={planDetails} />
        <Testimonials />
        <Faqs planDetails={planDetails} />
      </Main>
    </ContentLayout>
  )
}

const Main = styled.div({
  margin: '0 auto',
  padding: '25px 32px 80px',
  maxWidth: MAX_WIDTH,
  [bp.lg]: {
    padding: '42px 40px 91px',
    overflowX: 'hidden',
  },
  [bp.xl]: {
    padding: '42px 112px 91px 112px',
    overflowX: 'initial',
  },
  [bp.maxWidth]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
})

export { PlanDetails }
