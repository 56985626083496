import type { FC } from 'react'
import React from 'react'
import { useParams } from 'react-router'
import styled from '@emotion/styled'
import type { ServiceOrder, Claim } from '@helloextend/extend-api-client'
import { bp, COLOR, ContentLayout, Spinner, Text, Toast } from '@helloextend/customers-ui'
import { customLogger } from '@extend/client-helpers'
import { getIsRepairClaim } from '../../utils/get-is-repair'
import { ClaimStepperDetail } from './claim-stepper-detail'
import { ClaimDetail } from './claim-detail'
import { Faqs } from './faq'
import { useGetClaimDetailsData } from '../../hooks/use-get-claim-details-data'
import { getRTKQueryErrorMessage } from '@helloextend/extend-api-rtk-query'

export const getServiceTypeHeader = (
  serviceOrder: ServiceOrder | undefined,
  claim: Claim,
): string => {
  return getIsRepairClaim(serviceOrder || claim) ? 'Repair' : 'Replacement'
}

export const ClaimDetailsPage: FC = () => {
  const handleReload = (): void => {
    window.location.reload()
  }
  const { id: claimId } = useParams<{ id: string }>()
  const { claim, contract, isLoading, isError, serviceOrders, error } =
    useGetClaimDetailsData(claimId)
  const serviceOrder = serviceOrders?.[0]

  if (isLoading) {
    return (
      <ContentLayout pageTitle="My Claim Details">
        <SpinnerLoading>
          <Spinner size="md" />
        </SpinnerLoading>
      </ContentLayout>
    )
  }
  if (!claim || !contract || isError) {
    const errorMessage = getRTKQueryErrorMessage(error)

    customLogger.warn(`[Err: Claim Details Page] - ${errorMessage}`, {
      isError: isError || 'No RequestError',
      contractId: contract?.id || 'No Contract',
      claimId: claim?.id || 'No Claim',
    })

    return (
      <ContentLayout pageTitle="My Claim Details">
        <Toast
          dataCy="claim-details-page:error-toast"
          message="We've encountered an error. Please refresh this page."
          type="danger"
        >
          <RefreshButton onClick={handleReload}>Refresh</RefreshButton>
        </Toast>
      </ContentLayout>
    )
  }

  const serviceTypeHeader = getServiceTypeHeader(serviceOrder, claim)
  const isRepair = getIsRepairClaim(serviceOrder || claim)

  return (
    <ContentLayout pageTitle="My Claim Details">
      <PageWrapper>
        <ServiceType data-cy="claim-details-page:service-type">
          {serviceTypeHeader.toUpperCase()} CLAIM
        </ServiceType>
        <ClaimProductTitle data-cy="claim-details-page:product-title" numberOfLines={2}>
          {contract?.product.name || contract?.productsList?.[0]?.title}
        </ClaimProductTitle>
        <ClaimStepperDetail serviceOrder={serviceOrders} contract={contract} claim={claim} />
        <ClaimDetail serviceOrder={serviceOrders} contract={contract} claim={claim} />
        <Faqs
          fulfillmentType={
            isRepair
              ? 'repair'
              : serviceOrders?.[0]?.configurations?.replacementFulfillmentMethod || 'manual'
          }
        />
      </PageWrapper>
    </ContentLayout>
  )
}

const SpinnerLoading = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '0 20px',
})

const PageWrapper = styled.div({
  width: '100%',
  [bp.mobile]: {
    padding: '24px 20px 0',
  },
  [bp.desktop]: {
    padding: '60px 120px 0',
  },
})

const RefreshButton = styled.button({
  [bp.mobile]: {
    display: 'none',
  },
  [bp.desktop]: {
    display: 'flex',
    backgroundColor: COLOR.RED['200'],
    color: COLOR.RED['700'],
    fontSize: 14,
    borderRadius: 25,
    border: `1px solid ${COLOR.RED['700']}`,
    padding: '7px 26px',
    lineHeight: '19px',
    fontWeight: 700,
    marginLeft: 44,
  },
  '&:hover': {
    cursor: 'pointer',
  },
})

const ClaimProductTitle = styled(Text)({
  [bp.mobile]: {
    margin: '8px 0 24px',
    fontSize: 24,
    lineHeight: '32px',
  },
  [bp.desktop]: {
    margin: '16px 0 40px',
    fontSize: 36,
    lineHeight: '48px',
  },
})

const ServiceType = styled.div({
  fontWeight: 700,
  [bp.mobile]: {
    fontSize: 13,
    lineHeight: '16px',
  },
  [bp.desktop]: {
    fontSize: 14,
    lineHeight: '18px',
  },
})
