import type { FC } from 'react'
import styled from '@emotion/styled'
import { COLOR, Stack } from '@extend/zen'
import { ShippingProtectionCharityContent, images } from '../../../../lib/assets'
import { isMobile } from 'react-device-detect'
import { bp } from '@helloextend/customers-ui'
import { useScreenSize } from '@helloextend/client-hooks'

interface CharityProps {
  charityPlanDetailsHeadline?: string
  charityPlanDetailsSubheadline?: string
  charityPlanDetailsImage?: string
}

const Charity: FC<CharityProps> = ({
  charityPlanDetailsHeadline,
  charityPlanDetailsSubheadline,
  charityPlanDetailsImage,
}) => {
  const screenSize = useScreenSize()
  const header =
    charityPlanDetailsHeadline ?? ShippingProtectionCharityContent.charityPlanDetailsHeadline
  const description =
    charityPlanDetailsSubheadline ?? ShippingProtectionCharityContent.charityPlanDetailsSubheadline
  const image = charityPlanDetailsImage ?? images.charity
  const altImage = 'Charitable Donation'
  const spacing = 2

  if (isMobile || screenSize == 'medium' || screenSize == 'small') {
    return (
      <Stack align="center" spacing={spacing}>
        <MainTitle>{header}</MainTitle>
        <Img src={image || images.spWoman} alt={altImage} />
        <Description>{description}</Description>
      </Stack>
    )
  } else {
    return (
      <Stack isRow align="center" spacing={spacing}>
        <Img src={image || images.spWoman} alt="SP Woman" />

        <Stack align="stretch" spacing={spacing}>
          <MainTitle>{header}</MainTitle>
          <Description>{description}</Description>
        </Stack>
      </Stack>
    )
  }
}

const MainTitle = styled.div({
  [bp.mobile]: {
    fontSize: 28,
    lineHeight: '38px',
    fontWeight: 800,
    width: 345,
    textAlign: 'center',
  },
  [bp.desktop]: {
    fontSize: 52,
    lineHeight: '57px',
    fontWeight: 600,
    paddingBottom: 16,
    width: '100%',
    textAlign: 'center',
  },
})

const Description = styled.div({
  lineHeight: '30px',
  fontWeight: 400,
  textAlign: 'center',
  color: COLOR.NEUTRAL[700],

  [bp.mobile]: {
    fontSize: 18,
    width: 345,
  },
  [bp.desktop]: {
    fontSize: 20,
    width: '100%',
  },
})

const Img = styled.img({
  maxWidth: '400px',
  maxHeight: '600px',
  borderRadius: '24px',
  transform: 'scale',
})

export { Charity }
