import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery, X_EXTEND_ACCESS_TOKEN } from '../base-query'
import type {
  ClaimSessionLog,
  ConnectRequest,
  ConnectResponse,
  UpdateRequest,
  UpdateResponse,
} from './types'

export const incredibotApi = createApi({
  baseQuery,
  reducerPath: 'Incredibot',
  tagTypes: ['ClaimSessionLogs'],
  endpoints: (build) => ({
    getSessionLogsList: build.query<ClaimSessionLog[], string>({
      providesTags: (_, _err, sessionId) => [{ type: 'ClaimSessionLogs', id: sessionId }],
      query: (sessionId: string) => {
        if (!sessionId) {
          throw new Error('sessionId is missing from the claim')
        }

        return {
          url: `/claims-assistant/session/${sessionId}/logs`,
          method: 'GET',
        }
      },
    }),
    connect: build.mutation<ConnectResponse, ConnectRequest>({
      query: (data: ConnectRequest) => ({
        url: `/claims-assistant/connect`,
        method: 'POST',
        body: {
          apiVersion: 'latest',
          ...data,
        },
      }),
    }),
    update: build.mutation<UpdateResponse, UpdateRequest>({
      query: ({ accessToken, data }: UpdateRequest) => ({
        url: `/claims-assistant/update`,
        method: 'POST',
        body: data,
        headers: {
          [X_EXTEND_ACCESS_TOKEN]: accessToken,
        },
      }),
    }),
  }),
})

export const { useGetSessionLogsListQuery, useConnectMutation, useUpdateMutation } = incredibotApi
