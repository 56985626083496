import type { FC } from 'react'
import React from 'react'
import type { ServiceOrder } from '@helloextend/extend-api-client'
import type { MerchantServicingSettings } from '@helloextend/extend-api-rtk-query/src/servicers/types'
import { bp } from '@helloextend/customers-ui'
import styled from '@emotion/styled'
import { Timeline, TimelineItem, Button } from '@extend/zen'
import { isDepotRepair } from '../../../lib/type-guards'

export interface ClaimPendingExtendOwnedLabelTimelineDetailsProps {
  serviceOrder: ServiceOrder
  merchantServicingSettings: MerchantServicingSettings
}

export const ClaimPendingExtendOwnedLabelTimeline: FC<
  ClaimPendingExtendOwnedLabelTimelineDetailsProps
> = ({ serviceOrder, merchantServicingSettings }) => {
  const handleClick = (): void => {
    if (serviceOrder.shippingLabels?.length) {
      const image = serviceOrder.shippingLabels[0].imageData
      const w = window.open('')
      const img = new Image()
      img.src = `data:image/png;base64,${image}`
      w?.document.write(img.outerHTML)
      setTimeout(() => {
        w?.print()
        w?.close()
      }, 250)
    }
  }
  return (
    <ClaimCardContainer>
      <Timeline>
        <TimelineItem label="Claim approved" state="complete" data-cy="claim-approved" />
        {serviceOrder.serviceType === 'replace' &&
          !merchantServicingSettings?.isCustomerFulfillmentSelectionDisabled && (
            <TimelineItem label="Payout option selected" state="complete" data-cy="payout-option" />
          )}
        <TimelineItem
          data-cy="awaiting-customer-return"
          state="current"
          label="Awaiting the customer to return the defective product"
        />
        {isDepotRepair(serviceOrder) && (
          <TimelineItem
            label="Product repair by servicer"
            data-cy="product-repair"
            state="upcoming"
          />
        )}
        <TimelineItem label="Claim fulfilled" data-cy="claim-fulfilled" state="upcoming" />
      </Timeline>
      <TimelineFooter>
        <div>
          <Button
            data-cy="download-label-button"
            text="Download shipment label"
            emphasis="high"
            onClick={handleClick}
          />
        </div>
      </TimelineFooter>
    </ClaimCardContainer>
  )
}
const ClaimCardContainer = styled.div({
  width: '100%',
})

const TimelineFooter = styled.div({
  width: '100%',
  display: 'flex',
  [bp.mobile]: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  [bp.desktop]: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
})
