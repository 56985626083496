import React from 'react'
import type { FC } from 'react'
import {
  Timeline,
  TimelineItem,
  Stack,
  Icon,
  COLOR,
  LinkButton,
  OpenInNew,
  SentimentCalm,
  Button,
} from '@extend/zen'
import styled from '@emotion/styled'
import { bp } from '@helloextend/customers-ui'
import type { Claim, ServiceOrder } from '@helloextend/extend-api-client'
import {
  CLAIM_FULFILLED_TEXT,
  DEPOT_REPAIR_FULFILLED_TEXT,
  DIRECT_PAYMENT_LOOKOUT_TEXT,
  DIRECT_PAYMENT_TEXT,
  ONSITE_REPAIR_FULFILLED_TEXT,
  PAYOUT_OPTION_TEXT,
  VIRTUAL_CARD_BUTTON_TEXT,
  VIRTUAL_CARD_TEXT,
} from './contstants'
import { formatDate } from '../../../lib/helper-functions'
import { V2 } from '@extend-services/service-orders'

interface FulfillmentTimelineProps {
  claim: Claim
  serviceOrder: ServiceOrder
}

const FulfilledTimeline: FC<FulfillmentTimelineProps> = ({ claim, serviceOrder }) => {
  const getPayoutOptionText = (): string => {
    if (!serviceOrder.configurations) return ''
    const { replacementFulfillmentMethod } = serviceOrder.configurations
    if (replacementFulfillmentMethod === 'virtual_card') return VIRTUAL_CARD_TEXT
    if (replacementFulfillmentMethod === 'direct_payment') return DIRECT_PAYMENT_TEXT
    return ''
  }

  const getTimelineLabel = (): JSX.Element => {
    const fulfilledDate = claim?.fulfilledAt

    if (claim.serviceType === 'repair_depot') {
      return (
        <p data-cy="fulfilled-label">
          <TimelineBold>{CLAIM_FULFILLED_TEXT}: </TimelineBold>
          {DEPOT_REPAIR_FULFILLED_TEXT}
          {fulfilledDate ? ` on ${formatDate(fulfilledDate)}` : ''}
        </p>
      )
    }

    if (claim.serviceType === 'repair') {
      const repairFulfillmentDate = serviceOrder?.repairMetaData?.repairCompletedAt
      const repairTextMap: Record<V2.Models.RepairFulfillmentMethod, string> = {
        cleaning_kit: `cleaning kit shipped${
          repairFulfillmentDate ? ` on ${formatDate(repairFulfillmentDate)}` : ''
        }`,
        self_repair: `part shipped${
          repairFulfillmentDate ? ` on ${formatDate(repairFulfillmentDate)}` : ''
        }`,
        carry_in: `your product is fixed and ready for pick up${
          repairFulfillmentDate ? ` ${formatDate(repairFulfillmentDate)}` : ''
        }`,
        repair_depot: `${DEPOT_REPAIR_FULFILLED_TEXT}${
          fulfilledDate ? ` on ${formatDate(fulfilledDate)}` : ''
        }`,
        repair_onsite: `${ONSITE_REPAIR_FULFILLED_TEXT}${
          fulfilledDate ? ` on ${formatDate(fulfilledDate)}` : ''
        }`,
      }
      return (
        <p data-cy="fulfilled-label">
          <TimelineBold>{CLAIM_FULFILLED_TEXT}: </TimelineBold>
          {repairTextMap[serviceOrder.configurations?.repairFulfillmentMethod ?? 'carry_in']}
        </p>
      )
    }

    if (claim.serviceType === 'repair_onsite') {
      return (
        <p data-cy="fulfilled-label">
          <TimelineBold>{CLAIM_FULFILLED_TEXT}: </TimelineBold>
          {ONSITE_REPAIR_FULFILLED_TEXT}
          {fulfilledDate ? ` on ${formatDate(fulfilledDate)}` : ''}
        </p>
      )
    }
    return (
      <p data-cy="fulfilled-label">
        <TimelineBold>{CLAIM_FULFILLED_TEXT}</TimelineBold>
        {fulfilledDate ? `: ${formatDate(fulfilledDate)}` : ''}
      </p>
    )
  }

  return (
    <Container data-cy="claim-fulfilled-timeline">
      <Timeline>
        <TimelineItem label={getTimelineLabel()} state="success">
          {claim.serviceType === 'replace' && (
            <Stack spacing={1}>
              <p data-cy="payout-option-text">
                <TimelineBold>{PAYOUT_OPTION_TEXT}:</TimelineBold> {getPayoutOptionText()}
              </p>
            </Stack>
          )}
        </TimelineItem>
      </Timeline>
      <ReplacementFooter>
        {serviceOrder.configurations?.replacementFulfillmentMethod === 'direct_payment' && (
          <Stack isRow align="center">
            <Icon icon={SentimentCalm} data-cy="direct-payment-icon" />
            {DIRECT_PAYMENT_LOOKOUT_TEXT}
          </Stack>
        )}
        {serviceOrder.configurations?.replacementFulfillmentMethod === 'virtual_card' && (
          <>
            {serviceOrder.fulfillmentMetaData?.status === 'completed' ? (
              <div>
                <LinkButton
                  color="neutral"
                  data-cy="link-to-vc-button"
                  emphasis="medium"
                  text={VIRTUAL_CARD_BUTTON_TEXT}
                  to={`/my_claims/virtual_cards/${claim.id}`}
                  icon={OpenInNew}
                />
              </div>
            ) : (
              <div>
                <Button
                  color="neutral"
                  data-cy="link-to-vc-button-loading"
                  emphasis="medium"
                  text={VIRTUAL_CARD_BUTTON_TEXT}
                  isProcessing
                  isDisabled
                />
              </div>
            )}
          </>
        )}
      </ReplacementFooter>
    </Container>
  )
}

const TimelineBold = styled.span({
  fontWeight: 700,
})

const ReplacementFooter = styled.div({
  color: COLOR.NEUTRAL[800],
  fontWeight: 700,
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  [bp.mobile]: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  [bp.desktop]: {
    alignItems: 'flex-start',
    flexDirection: 'row',
  },
})

const Container = styled.div({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
})

export { FulfilledTimeline }
