import type { FC } from 'react'
import React, { useState } from 'react'
import styled from '@emotion/styled'
import { COLOR, bp } from '@helloextend/customers-ui'
import { useTheme } from '@emotion/react'
import { NavBar } from './navbar'

interface HeaderProps {
  isLoggedIn: boolean
}

// TODO: RV - passing properties into the header will work short term, but lets chat about
//  using composition instead to make these components more flexible.
const Header: FC<HeaderProps> = ({ isLoggedIn }) => {
  const [showMenu, setShowMenu] = useState(false)

  const openMenu = (): void => {
    setShowMenu(true)
  }

  const closeMenu = (): void => {
    setShowMenu(false)
  }
  const theme = useTheme()

  const background = theme.merchantConfiguration.brandingConfigurations.primaryColor

  return (
    <HeaderWrapper role="banner" background={background}>
      <NavBar {...{ showMenu, closeMenu, openMenu, isLoggedIn, theme }} />
    </HeaderWrapper>
  )
}

const HeaderWrapper = styled.header<{ background: string }>(({ background }) => ({
  position: 'fixed',
  zIndex: 20,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100vw',
  background,
  borderBottom: `1px solid ${COLOR.NEUTRAL.OPACITY[50]}`,
  height: 48,
  [bp.desktop]: {
    height: 72,
  },
}))

export type { HeaderProps }
export { Header }
