import React, { useState } from 'react'
import type { FC } from 'react'
import {
  Button,
  Timeline,
  TimelineItem,
  Stack,
  RowOptionGroup,
  RowOption,
  ArrowDropDown,
  ArrowDropDownClose,
  Icon,
  COLOR,
  Accordion,
  useToaster,
  ToastColor,
  ToastDuration,
} from '@extend/zen'
import type { RowOptionProps } from '@extend/zen'
import styled from '@emotion/styled'
import type { ReplacementFulfillmentMethod } from '@extend-services/service-orders/dist/src/client/api-rest/v2/models'
import type { MerchantServicingSettings } from '@helloextend/extend-api-rtk-query/src/servicers/types'
import { bp } from '@helloextend/customers-ui'
import type { Claim, ServiceOrder } from '@helloextend/extend-api-client'
import { ClaimType, ContractType } from '@helloextend/extend-api-client'
import { currency } from '@extend/client-helpers'
import { useSelectFulfillmentMethodMutation } from '@helloextend/extend-api-rtk-query'
import type { ContractsSearchIndividual } from '../../../types/contract'
import { LDFlag } from '../../../constants/ld-flags'
import { useFlags } from 'launchdarkly-react-client-sdk'

interface CustomerPayoutTimelineProps {
  claim: Claim
  sellerName?: string
  serviceOrder: ServiceOrder
  merchantServicingSettings: MerchantServicingSettings
  contract?: ContractsSearchIndividual
}

const selectedOptionToastMap: Record<ReplacementFulfillmentMethod, string> = {
  virtual_card: 'Virtual Visa Card issued! This may take a moment until the link is ready.',
  direct_payment: 'Payout option selected. Look out for an email!',
  manual: '',
  discount_code: '',
  automated_replacement:
    'Automatic product replacement selected! This may take a moment while we process the order, look out for an email!',
}

const CustomerPayoutTimeline: FC<CustomerPayoutTimelineProps> = ({
  claim,
  sellerName,
  serviceOrder,
  merchantServicingSettings,
  contract,
}) => {
  const { [LDFlag.AutomatedReplacementFulfillment]: FF_AUTOMATED_REPLACEMENT_FULFILLMENT } =
    useFlags()

  const getDefaultPayoutMethod = (): ReplacementFulfillmentMethod => {
    if (
      !merchantServicingSettings.supportedCustomerFulfillmentMethods ||
      merchantServicingSettings.supportedCustomerFulfillmentMethods.includes('virtual_card')
    )
      return 'virtual_card'

    return merchantServicingSettings.supportedCustomerFulfillmentMethods.includes('direct_payment')
      ? 'direct_payment'
      : 'manual'
  }

  const [displayOptions, setDisplayOptions] = useState(false)
  const [displayPaymentDetails, setDisplayPaymentDetails] = useState(false)
  const [selectedOption, setSelectedOption] = useState<ReplacementFulfillmentMethod>(
    getDefaultPayoutMethod(),
  )
  const [setPayoutMethod] = useSelectFulfillmentMethodMutation()
  const { toast } = useToaster()

  const handleSelectPayoutButtonClick = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.stopPropagation()
    setDisplayOptions(true)
  }

  const handleOptionChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setSelectedOption(e.target.value as ReplacementFulfillmentMethod)
  }

  const handleConfirmPayoutClick = async (): Promise<void> => {
    if (!serviceOrder) return
    try {
      const response = await setPayoutMethod({
        serviceOrderId: serviceOrder?.id,
        fulfillmentSelection: selectedOption,
      })
      if ((response as { error: { status: number; data: unknown } }).error) {
        toast({
          message: 'An error occurred',
          toastColor: ToastColor.red,
          toastDuration: ToastDuration.short,
        })
        return
      }
      toast({
        message: selectedOptionToastMap[selectedOption],
        toastColor: ToastColor.green,
        toastDuration: ToastDuration.short,
      })
    } catch (e) {
      toast({
        message: 'An error occurred',
        toastColor: ToastColor.red,
        toastDuration: ToastDuration.short,
      })
    }
  }

  const calculateVirtualCardBuffer = (): number => {
    if (claim.type === 'shipping_protection' || !serviceOrder.totalItemsValue) return 0

    return serviceOrder.totalItemsValue.amount > 10000
      ? Math.round(serviceOrder.totalItemsValue.amount * 0.2)
      : 2000
  }

  const generateRowOptionProps = (): RowOptionProps[] => {
    const props = []
    if (
      !merchantServicingSettings.supportedCustomerFulfillmentMethods ||
      merchantServicingSettings.supportedCustomerFulfillmentMethods.includes('virtual_card')
    ) {
      props.push({
        'data-cy': 'virtual-card-option',
        value: 'virtual_card',
        badgeText: 'Best value',
        label: `Virtual Visa Card for ${sellerName ?? 'store'}`,
        description: 'Issued instantly for your purchase with the same merchant',
      })
    }
    if (
      !merchantServicingSettings.supportedCustomerFulfillmentMethods ||
      merchantServicingSettings.supportedCustomerFulfillmentMethods.includes('direct_payment')
    ) {
      props.push({
        'data-cy': 'direct-payment-option',
        value: 'direct_payment',
        label: 'Bank account transfer',
        description: 'A direct transfer of funds. It takes 3-5 business days to process',
      })
    }
    if (
      serviceOrder.claimType === ClaimType.SHIPPING_PROTECTION &&
      merchantServicingSettings.supportedCustomerFulfillmentMethods?.includes(
        'automated_replacement',
      ) &&
      FF_AUTOMATED_REPLACEMENT_FULFILLMENT
    ) {
      props.push({
        'data-cy': 'automated-replacement-option',
        value: 'automated_replacement',
        label: 'Automatic product replacement',
        description: 'Automatically replace with the same product, pending availability.',
      })
    }
    return props
  }

  const shouldDisableMyClaimsRedirect = [
    ContractType.PRODUCT_PROTECTION_BUNDLE,
    ContractType.CATEGORY,
  ].includes(contract?.type as ContractType)

  return (
    <Container data-cy="customer-payout-timeline">
      <Timeline>
        <TimelineItem label="Claim approved" state="complete" />
        {displayOptions ? (
          <TimelineItem
            label="Payout option"
            state="current"
            primaryButton={{
              'data-cy': 'confirm-payout-options-button',
              text: 'Confirm payout option',
              emphasis: 'high',
              onClick: handleConfirmPayoutClick,
            }}
            secondaryButton={
              !shouldDisableMyClaimsRedirect
                ? {
                    'data-cy': 'faq-button',
                    text: 'Frequently Asked Questions',
                    emphasis: 'low',
                    to: `/my_claims/${claim.id}`,
                  }
                : undefined
            }
          >
            <Stack spacing={2} justify="start">
              <RowOptionGroup
                value={selectedOption}
                name="payout_option"
                onChange={handleOptionChange}
                data-cy="payout-option-group"
              >
                {generateRowOptionProps().map((props) => (
                  <RowOption key={props.value} {...props} />
                ))}
              </RowOptionGroup>
              {!['manual', 'automated_replacement'].includes(selectedOption) && (
                <PaymentContainer>
                  <PaymentHeader
                    onClick={() => setDisplayPaymentDetails(!displayPaymentDetails)}
                    data-cy="payment-header"
                  >
                    <Icon icon={displayPaymentDetails ? ArrowDropDownClose : ArrowDropDown} />
                    Payout Total: &nbsp;{' '}
                    <span className="amount" data-cy="total-amount">
                      {currency.format(
                        (serviceOrder.totalItemsValue?.amount ?? 0) +
                          (selectedOption === 'virtual_card' ? calculateVirtualCardBuffer() : 0),
                      )}
                    </span>
                  </PaymentHeader>
                  <Accordion isExpanded={displayPaymentDetails}>
                    <PaymentDetailsContainer>
                      <p data-cy="base-price">
                        Product price: {currency.format(serviceOrder.totalItemsValue?.amount ?? 0)}
                      </p>
                      {selectedOption === 'virtual_card' && claim.type !== 'shipping_protection' ? (
                        <p data-cy="virtual-card-buffer">
                          Complementary shipping and taxes:{' '}
                          {currency.format(calculateVirtualCardBuffer())}
                        </p>
                      ) : (
                        <p data-cy="direct-payment-text">
                          Your contract only covers the purchase price of the product. If you
                          repurchase from the merchant using Virtual Visa Card, shipping and taxes
                          are on us!
                        </p>
                      )}
                    </PaymentDetailsContainer>
                  </Accordion>
                </PaymentContainer>
              )}
            </Stack>
          </TimelineItem>
        ) : (
          <TimelineItem label="Payout option" state="current" />
        )}
        {!merchantServicingSettings.fieldDestroyEnabled && (
          <TimelineItem
            data-cy="ship-product-item"
            label="Defective product shipped by customer"
            state="upcoming"
          />
        )}
        <TimelineItem label="Claim fulfilled" state="upcoming" />
      </Timeline>
      {!displayOptions && (
        <TimelineFooter>
          <div>
            <Button
              data-cy="display-payout-options-button"
              text="Select payout options"
              emphasis="high"
              onClick={handleSelectPayoutButtonClick}
            />
          </div>
        </TimelineFooter>
      )}
    </Container>
  )
}

const PaymentContainer = styled.div({
  color: COLOR.NEUTRAL[600],
  fontWeight: 500,
  fontSize: 13,
})

const PaymentHeader = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  fontWeight: 600,
  '.amount': {
    color: COLOR.NEUTRAL[1000],
    fontWeight: 500,
    fontSize: 15,
  },
  cursor: 'pointer',
})

const TimelineFooter = styled.div({
  width: '100%',
  display: 'flex',
  [bp.mobile]: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  [bp.desktop]: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
})

const Container = styled.div({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  button: {
    display: 'block !important',
  },
  gap: 15,
})

const PaymentDetailsContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: 5,
  marginTop: 5,
  p: {
    paddingLeft: 24,
  },
})

export { CustomerPayoutTimeline }
