import type { ChatReducerState } from './index'
import type { AccessTokenReducerState } from './access-token'
import type { PromptReducerState } from './prompt'
import type { MessagesReducerState } from './messages'
import type { SlotsReducerState } from './slots'
import type { ChatMessage } from '../../types/chat-message'
import type { ChatLoadingReducerState } from './is-loading'

type Selector<T> = (state: ChatReducerState) => T

export const getMessages: Selector<MessagesReducerState> = (state) => state.messages
export const getIsLoading: Selector<ChatLoadingReducerState> = (state) => state.isLoading
export const getPrompt: Selector<PromptReducerState['prompt']> = (state) => state.prompt.prompt
export const getIsPromptHidden: Selector<PromptReducerState['isPromptHidden']> = (state) =>
  state.prompt.isPromptHidden
export const getAccessToken: Selector<AccessTokenReducerState> = (state) => state.accessToken
export const getLastPromptSlot: Selector<PromptReducerState['lastPromptSlot']> = (state) =>
  state.prompt.lastPromptSlot
export const getSlots: Selector<SlotsReducerState> = (state) => state.slots
export const getRemainingMessages: Selector<ChatMessage[]> = (state) => {
  const messages = getMessages(state)
  const lastUserIndex = messages.findIndex((message) => !message.bot)
  return messages.slice(lastUserIndex + 2)
}
