import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { Badge, COLOR, OpenInNew, Subheading, Package, Icon } from '@extend/zen'
import {
  useGetMerchantServicingSettingsQuery,
  usePrecheckQuery,
} from '@helloextend/extend-api-rtk-query'
import { isMobile } from 'react-device-detect'
import {
  formatDate,
  getDisplayDetails,
  getIsClaimInProgress,
  mapContractStatusBadge,
  getShipmentIssueTooltip,
} from '../../../lib/helper-functions'
import type { ShippingProtectionContract } from '../../../types/contract'
import { mapTrackingData } from '../../../lib/shipping-protection-utils'
import { Card, CardGroupItemProps } from '../../../components/card'
import { isPrecheckWithLineItems } from '../../../lib/type-guards'

export interface ShippingProtectionCardProps {
  contract: ShippingProtectionContract
  onFileClaimClick: (handlerArgs: {
    contractId: string
    isMerchantOwnedIntake?: boolean
    lineItemIds?: string[]
    shipmentId?: string
  }) => void
  isMissingRequiredProfileField?: boolean
}

const statusColorMap: Record<string, 'red' | 'blue' | 'green' | 'yellow' | 'neutral'> = {
  Active: 'green',
  Cancelled: 'red',
  Refunded: 'red',
  Fulfilled: 'neutral',
  Expired: 'neutral',
  Other: 'neutral',
}

const ShippingProtectionCard: FC<ShippingProtectionCardProps> = ({
  contract,
  onFileClaimClick,
  isMissingRequiredProfileField = false,
}) => {
  const { data: precheck, isLoading } = usePrecheckQuery({ contractId: contract.id })
  const { data: merchantServicingSettings } = useGetMerchantServicingSettingsQuery({
    sellerId: contract.sellerId,
  })

  const { sellerName, purchaseDate, productsList, trackingInfo } = contract

  const { isClaimFilingDisabled, fileClaimTooltip, subBadgeText } = getDisplayDetails({
    precheck,
    contract,
  })

  const { trackedProducts, untrackedProducts } = mapTrackingData(contract)

  const BadgePreHeading = () => {
    return (
      <BadgeWrapper>
        <Icon icon={Package} color={COLOR.NEUTRAL[600]} />
        <StyledSubheading data-cy="sp-card-subheading">Shipping Protection</StyledSubheading>
        {!isLoading && (
          <>
            <Badge
              text={mapContractStatusBadge(contract.status)}
              color={statusColorMap[mapContractStatusBadge(contract.status)]}
              data-cy="sp-card-badge"
              icon={isMobile ? Package : undefined}
            />
            {getIsClaimInProgress(precheck, contract.productsList) && (
              <ClaimInProgressWrapper>
                <Badge
                  emphasis="low"
                  text={subBadgeText || 'Claim in Progress'}
                  data-cy="sp-card-claim-progress-badge"
                  color="green"
                />
              </ClaimInProgressWrapper>
            )}
          </>
        )}
      </BadgeWrapper>
    )
  }

  let hasActiveClaim = false
  const trackedGroups = trackedProducts?.map(({ trackingInfo, products }, index) => {
    if (products.length) {
      if (isPrecheckWithLineItems(precheck)) {
        // If any products on the shipment have active claims, disable the file claim button
        hasActiveClaim = products.some(
          (product) => precheck?.lineItems?.[product.lineItemId]?.hasActiveClaim,
        )
      }

      const shipmentIssueTooltip = getShipmentIssueTooltip({
        isMissingRequiredProfileField,
        fileClaimTooltip,
        hasActiveClaim,
        hasTrackingInfo: true,
      })

      return {
        preHeading: `Ship ${index + 1} · `,
        heading: trackingInfo.actualDeliveryDate
          ? `Delivered ${formatDate(trackingInfo.actualDeliveryDate)}`
          : trackingInfo.estimatedDeliveryDate
          ? `Arriving ${formatDate(trackingInfo.estimatedDeliveryDate)}`
          : 'Pending shipment details',
        primaryButtonProps: {
          emphasis: 'medium',
          text: 'Report Shipment Issue',
          isDisabled:
            !precheck || hasActiveClaim || isClaimFilingDisabled || isMissingRequiredProfileField,
          tooltip: shipmentIssueTooltip,
          onClick: () =>
            onFileClaimClick({
              contractId: contract.id,
              isMerchantOwnedIntake: merchantServicingSettings?.isMerchantOwnedIntake,
              lineItemIds: products.map(
                (product) => (product as { lineItemId: string }).lineItemId,
              ),
              shipmentId: trackingInfo?.shipmentId,
            }),
        },
        secondaryButtonProps: {
          emphasis: 'medium',
          icon: OpenInNew,
          text: 'Track Package',
          isDisabled: !(!!trackingInfo?.trackingUrl || !!trackingInfo?.requestTrackingUrl),
          onClick: () => {
            if (trackingInfo?.trackingUrl || trackingInfo?.requestTrackingUrl) {
              window.open(trackingInfo?.trackingUrl || trackingInfo?.requestTrackingUrl, '_blank')
            }
          },
          tooltip: 'Track on carrier website',
        },
        listItems: products.map((product) => ({
          image: product?.imageUrl,
          text: product?.title,
          itemsShowMoreText: 'View all items',
          itemsShowLessText: 'View less items',
        })),
        showMoreGroupsText: 'View all shipments',
        showLessGroupsText: 'View less shipments',
      }
    }
  }) as CardGroupItemProps[]

  hasActiveClaim = false
  if (isPrecheckWithLineItems(precheck)) {
    // For the untracked products, only disable the file claim button if all products have active claims
    hasActiveClaim = untrackedProducts.every(
      (product) => precheck?.lineItems?.[product.lineItemId]?.hasActiveClaim,
    )
  }
  const shipmentIssueTooltip = getShipmentIssueTooltip({
    isMissingRequiredProfileField,
    fileClaimTooltip,
    hasActiveClaim,
    hasTrackingInfo: false,
  })
  const untrackedGroup =
    untrackedProducts?.length &&
    ({
      heading: 'Pending shipment details',
      primaryButtonProps: {
        emphasis: 'medium',
        text: 'Report Shipment Issue',
        isDisabled:
          !precheck || hasActiveClaim || isClaimFilingDisabled || isMissingRequiredProfileField,
        tooltip: shipmentIssueTooltip,
        onClick: () =>
          onFileClaimClick({
            contractId: contract.id,
            isMerchantOwnedIntake: merchantServicingSettings?.isMerchantOwnedIntake,
            lineItemIds: untrackedProducts?.map(
              (product) => (product as { lineItemId: string }).lineItemId,
            ),
          }),
      },
      listItems: untrackedProducts?.map((product) => ({
        image: product?.imageUrl,
        text: product?.title,
        itemsShowMoreText: 'View all items',
        itemsShowLessText: 'View less items',
      })),
    } as CardGroupItemProps)

  return (
    <Card
      data-cy="shipping-protection"
      dataProps={{
        'data-extend-live': 'shipping-protection-card-wrapper',
        'data-contract-id': contract.id,
      }}
      // TODO: [POST-2355] remove override
      extendLiveOverride="shipping-protection-card"
      preHeading={<BadgePreHeading />}
      heading={`Order from ${sellerName}`}
      subHeading={`${trackingInfo?.length ?? 0} shipment(s) · ${
        productsList?.length
      } item(s) · Purchased ${formatDate(purchaseDate)}`}
      primaryGroups={trackedGroups || undefined}
      secondaryGroups={untrackedGroup ? [untrackedGroup] : undefined}
    />
  )
}

const BadgeWrapper = styled.div({
  display: 'flex',
  gap: '8px',
  alignItems: 'center',
})

const StyledSubheading = styled(Subheading)({
  color: COLOR.NEUTRAL[600],
  fontWeight: 700,
})

const ClaimInProgressWrapper = styled.div({
  alignSelf: 'flex-start',
})

export { ShippingProtectionCard }
