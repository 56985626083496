import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { bp } from '@helloextend/customers-ui'

const PlansPageHeader: FC = () => {
  return (
    <HeaderWrapper>
      <Title data-cy="plans-page-header-title">My Orders</Title>
    </HeaderWrapper>
  )
}

const HeaderWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  [bp.mobile]: {
    marginLeft: '16px',
  },
  [bp.desktop]: {
    marginLeft: 0,
  },
})

const Title = styled.div({
  [bp.mobile]: {
    fontSize: '28px',
    fontWeight: 700,
    lineHeight: '38px',
  },
  [bp.desktop]: {
    fontSize: '44px',
    fontWeight: 800,
    lineHeight: '60px',
  },
})

export { PlansPageHeader }
