import type { FC } from 'react'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from '@emotion/styled'
import type { ServiceOrder, Claim, ContractsV2GetResponse } from '@helloextend/extend-api-client'
import { COLOR, bp, Button, Grid, Toast } from '@helloextend/customers-ui'
import { date } from '@extend/client-helpers'

import { getIsRepairClaim } from '../../utils/get-is-repair'
import { logEvent } from '../../analytics'
import { images } from '../../lib/assets'

export const getServiceOrderHeaderServiceType = (
  serviceOrder: ServiceOrder[] | undefined,
  claim: Claim,
): string => {
  const serviceType = serviceOrder?.length ? serviceOrder[0] : claim
  if (getIsRepairClaim(serviceType)) {
    return 'Repair'
  }
  return 'Replacement'
}

interface ClaimDetailsProps {
  claim: Claim
  contract?: ContractsV2GetResponse
  serviceOrder?: ServiceOrder[]
}

const ClaimDetail: FC<ClaimDetailsProps> = ({ claim, contract, serviceOrder }) => {
  const [isToastVisible, setIsToastVisible] = useState(false)
  const history = useHistory()
  const serviceTypeHeader = getServiceOrderHeaderServiceType(serviceOrder, claim)
  const customerName = claim?.customer.name

  const viewPlan = (): void => {
    logEvent('Claims - Clicks', 'View Plan')
    history.push(`/my_plans/${contract?.id}`)
  }

  const handleToastDismiss = (): void => {
    setIsToastVisible(false)
  }

  const handleDisabledClick = (): void => {
    if (!contract) {
      setIsToastVisible(true)
    }
  }

  return (
    <ClaimDetailsCard data-cy="claim-details-page:claim-detail">
      <Toast
        isDismissible
        type="warning"
        message="You cannot view a plan that has been transferred."
        isVisible={isToastVisible}
        handleDismiss={handleToastDismiss}
      />
      <ClaimDetailsWrapper>
        <ClaimDetailsHeader data-cy="claim-details:header">
          {serviceTypeHeader} Claim Details
        </ClaimDetailsHeader>
        <ProductImage
          data-cy="claim-details:product-image-1"
          isMobile
          image={contract?.product.imageUrl}
          title={contract?.product.name}
        />
        <ClaimDetailsContainer>
          <ClaimDetailSubHeader data-cy="claim-details:subheader">
            Claim Details
          </ClaimDetailSubHeader>
          <FieldGroup>
            <FieldItem>
              <FieldTitle>CLAIM ID</FieldTitle>
              <FieldContent data-cy="claim-details:claim-id-content">{claim.id}</FieldContent>
            </FieldItem>
            {(customerName || contract) && (
              <FieldItem>
                <FieldTitle>CUSTOMER NAME</FieldTitle>
                <FieldContent data-cy="claim-details:customer-name-content">
                  {customerName || contract?.customer.name}
                </FieldContent>
              </FieldItem>
            )}
            <FieldItem>
              <FieldTitle>FILING DATE</FieldTitle>
              <FieldContent data-cy="claim-details:filing-date-content">
                {date.format(claim.createdAt)}
              </FieldContent>
            </FieldItem>
          </FieldGroup>
        </ClaimDetailsContainer>
        <div aria-hidden="true" onClick={handleDisabledClick}>
          <StyledButton
            dataCy="claim-details:view-plan-button"
            kind="secondary"
            text="View Plan"
            onClick={viewPlan}
            isDisabled={!contract}
          />
        </div>
      </ClaimDetailsWrapper>
      <ProductImage
        data-cy="claim-details:product-image-2"
        image={contract?.product.imageUrl}
        title={contract?.product.name}
      />
    </ClaimDetailsCard>
  )
}

const ClaimDetailsCard = styled.div({
  display: 'flex',
  justifyContent: 'space-between',

  border: `1px solid ${COLOR.NEUTRAL[200]}`,
  borderRadius: 16,
  [bp.mobile]: {
    flexDirection: 'column',
    margin: '60px 0',
    padding: '24px 16px',
  },
  [bp.desktop]: {
    flexDirection: 'row',
    margin: '80px 0',
    padding: 40,
  },
})

const ClaimDetailsWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
})

const ClaimDetailsHeader = styled.div({
  fontSize: 20,
  lineHeight: '28px',
  [bp.desktop]: {
    fontSize: 28,
    lineHeight: '36px',
  },
})

const ClaimDetailsContainer = styled.div({
  display: 'flex',
  [bp.mobile]: {
    flexDirection: 'column',
    marginTop: 24,
  },
  [bp.desktop]: {
    flexDirection: 'row',
    marginTop: 32,
  },
})

const FieldGroup = styled(Grid)({
  [bp.mobile]: {
    flexDirection: 'column',
    gap: 24,
    margin: '34px 0 24px',
  },
  [bp.desktop]: {
    flexDirection: 'row',
    gap: 72,
    height: 'auto',
    margin: 0,
  },
})

const FieldItem = styled(Grid)({
  flexDirection: 'column',
  justifyContent: 'flex-start',
  gap: 8,
})

const FieldTitle = styled.div({
  fontSize: 12,
  lineHeight: '18px',
  fontWeight: 700,
})

const FieldContent = styled.div({
  fontSize: 16,
  lineHeignt: '24px',
  fontWeight: 400,
})

const ClaimDetailSubHeader = styled.div({
  textDecoration: 'underline',
  textDecorationColor: COLOR.BLUE[800],
  textUnderlineOffset: '10px',
  [bp.desktop]: {
    display: 'none',
  },
})

const ProductImage = styled.div<{ image?: string; isMobile?: boolean }>(({ image, isMobile }) => ({
  verticalAlign: 'middle',
  maxHeight: 160,
  maxWidth: 160,
  minHeight: 160,
  minWidth: 160,
  marginTop: isMobile ? 24 : 0,
  marginLeft: isMobile ? 0 : 40,
  background: `no-repeat center center`,
  backgroundSize: 'cover',
  [bp.mobile]: {
    display: isMobile ? 'inline' : 'none',
    borderRadius: 8,
    backgroundImage: `url(${image}), url(${images.defaultMobileProductImage})`,
  },
  [bp.desktop]: {
    display: isMobile ? 'none' : 'inline',
    borderRadius: 16,
    backgroundImage: `url(${image}), url(${images.defaultProductImage})`,
  },
}))

const StyledButton = styled(Button)({
  padding: 0,
  borderRadius: 24,
  fontWeight: 700,
  [bp.mobile]: {
    width: 123,
    height: 38,
    fontSize: 16,
    lineHeight: '22px',
  },
  [bp.desktop]: {
    width: 190,
    height: 47,
    marginTop: 42,
    fontSize: 20,
    lineHeight: '27px',
  },
})

export { ClaimDetail }
