import type { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import type { SerializedError } from '@reduxjs/toolkit'

/**
 * Type predicate to narrow an unknown error to `FetchBaseQueryError`
 */
export function isRtkFetchBaseQueryError(error: unknown): error is FetchBaseQueryError {
  return typeof error === 'object' && error != null && 'status' in error
}

export function safeBtoa(s: string): string {
  try {
    return btoa(s)
  } catch (e) {
    console.error(e)
    return ''
  }
}

export const getRTKQueryErrorMessage = (
  error: FetchBaseQueryError | SerializedError | undefined,
): string => {
  return (error && 'error' in error && error.error) || 'Unknown Error'
}
