import React, { useEffect } from 'react'
import { FC } from 'react'
import { InputPrompt, Reply, Slot } from '@extend-incredibot/types'
import styled from '@emotion/styled'
import { Button, TextArea } from '@extend/zen'
import { bp } from '@helloextend/customers-ui'
import type * as Yup from 'yup'
import { useFormik } from 'formik'
import { DESCRIPTION_MAX, textInputSchema, UserInputComponentProps } from './types'

export interface TextInputProps extends UserInputComponentProps<Reply & { prompt: InputPrompt }> {}
type Value = Yup.InferType<typeof textInputSchema>

const TextInput: FC<TextInputProps> = ({ isLoading, onSubmit, reply }) => {
  const { validationType, placeholder } = reply.prompt

  const { values, errors, handleChange, handleSubmit, resetForm } = useFormik({
    validateOnChange: true,
    initialValues: { description: '' },
    validationSchema: textInputSchema,
    onSubmit: (input: Value): void => {
      if (reply.prompt?.slot) {
        onSubmit(reply.prompt.slot as Slot, input.description)
      }
    },
  })

  useEffect(() => {
    resetForm({ values: { description: '' } })
  }, [reply, resetForm])

  return (
    <ContentWrapper data-cy="text-input-content-wrapper">
      <form onSubmit={handleSubmit}>
          <TextArea
            data-cy="description-claim-new"
            id="description"
            value={values.description}
            placeholder={placeholder}
            maxLength={DESCRIPTION_MAX}
            onChange={handleChange}
            isDisabled={isLoading}
            isError={!!errors.description}
            errorFeedback={errors.description}
            rows={5}
            inputMode={validationType === 'number' ? 'decimal' : 'text'}
            color="neutral"
          />
        <ButtonWrapper>
          <Button
            data-cy="next-button"
            text="Next"
            isDisabled={!values.description || !!errors.description || isLoading}
            isProcessing={isLoading}
            type="submit"
            color={'neutral'}
          />
        </ButtonWrapper>
      </form>
    </ContentWrapper>
  )
}

const ContentWrapper = styled.div({
  width: '100%',
  display: 'inline-flex',
  justifyContent: 'center',
  flexDirection: 'column',
  textAlign: 'left',
  [bp.desktop]: {
    gap: 32,
  },
  [bp.mobile]: {
    gap: 16,
  },
})

const ButtonWrapper = styled.div({
  display: 'flex',
  justifyContent: 'center',
})

export { TextInput }
