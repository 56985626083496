export const VIRTUAL_CARD_TEXT = 'Virtual Visa Card'
export const DIRECT_PAYMENT_TEXT = 'Bank account transfer'
export const CLAIM_FULFILLED_TEXT = 'Claim fulfilled'
export const DEPOT_REPAIR_FULFILLED_TEXT = 'Repaired product shipped by servicer'
export const ONSITE_REPAIR_FULFILLED_TEXT = 'On-site repair completed by servicer'
export const PAYOUT_OPTION_TEXT = 'Payout option'
export const DIRECT_PAYMENT_LOOKOUT_TEXT = 'Look out for an email!'
export const VIRTUAL_CARD_BUTTON_TEXT = 'Use digital debit card'
export const AUTOMATED_REPLACEMENT_LOOKOUT_TEXT =
  'Look out for an email when the replacement is shipped!'
