import type { FC } from 'react'
import React, { useMemo, useState } from 'react'
import styled from '@emotion/styled'
import { CarouselProvider, Slide, Slider } from 'pure-react-carousel'
import 'pure-react-carousel/dist/react-carousel.es.css'
import { COLOR, DisplayLarge, Stack } from '@extend/zen'
import { bp } from '@helloextend/customers-ui'
import { v4 as uuid } from 'uuid'
import { useScreenSize } from '@helloextend/client-hooks'
import { Testimonial } from './testimonial'
import { SHIPPING_PROTECTION_TESTIMONIALS } from '../../../../lib/assets'

interface TestimonialsProps {
  testimonialsHeader?: string
  testimonial1Body?: string
  testimonial1AuthorName?: string
  testimonial2Body?: string
  testimonial2AuthorName?: string
  testimonial3Body?: string
  testimonial3AuthorName?: string
}

const Testimonials: FC<TestimonialsProps> = ({
  testimonialsHeader,
  testimonial1AuthorName,
  testimonial1Body,
  testimonial2AuthorName,
  testimonial2Body,
  testimonial3AuthorName,
  testimonial3Body,
}) => {
  const [maxHeight, setMaxHeight] = useState(0)

  const screenSize = useScreenSize()
  const isSmallScreen = screenSize === 'small'
  const CARD_WIDTH = 301
  const TOTAL_BUBBLE_HEIGHT = maxHeight + 68

  const BUBBLE_MARGIN_BOTTOM = isSmallScreen || screenSize === 'medium' ? 69 : 47

  const visibleSlidesCount = (): number => {
    if (screenSize === 'small') return 1
    if (screenSize === 'medium') return 2
    if (screenSize === 'large') return 2
    return 3
  }

  const testimonialsList = useMemo(() => {
    return [
      {
        name: testimonial1AuthorName || SHIPPING_PROTECTION_TESTIMONIALS[0].name,
        comment: testimonial1Body || SHIPPING_PROTECTION_TESTIMONIALS[0].comment,
      },
      {
        name: testimonial2AuthorName || SHIPPING_PROTECTION_TESTIMONIALS[1].name,
        comment: testimonial2Body || SHIPPING_PROTECTION_TESTIMONIALS[1].comment,
      },
      {
        name: testimonial3AuthorName || SHIPPING_PROTECTION_TESTIMONIALS[2].name,
        comment: testimonial3Body || SHIPPING_PROTECTION_TESTIMONIALS[2].comment,
      },
    ]
  }, [
    testimonial1AuthorName,
    testimonial1Body,
    testimonial2AuthorName,
    testimonial2Body,
    testimonial3AuthorName,
    testimonial3Body,
  ])

  const header = testimonialsHeader || 'Hear It From Our Happy Customers'
  const spacing = 2
  return (
    <Wrapper>
      <Stack spacing={spacing}>
        <DisplayLarge>
          <div style={{ textAlign: 'center' }}>{header}</div>
        </DisplayLarge>
        <CarouselProviderWrapper
          visibleSlides={visibleSlidesCount()}
          totalSlides={3}
          step={visibleSlidesCount()}
          naturalSlideWidth={CARD_WIDTH}
          naturalSlideHeight={TOTAL_BUBBLE_HEIGHT + BUBBLE_MARGIN_BOTTOM}
          touchEnabled={true}
          dragEnabled={true}
          isSmallScreen={isSmallScreen}
          bubbleheight={TOTAL_BUBBLE_HEIGHT}
        >
          <SliderContainer height={200} isSmallScreen={isSmallScreen}>
            <SliderWrapper>
              {testimonialsList.map((testimonial, i) => {
                return (
                  <Slide index={i} key={uuid()}>
                    <Testimonial
                      {...testimonial}
                      setMaxHeight={setMaxHeight}
                      maxHeight={maxHeight}
                    />
                  </Slide>
                )
              })}
            </SliderWrapper>
          </SliderContainer>
        </CarouselProviderWrapper>
      </Stack>
    </Wrapper>
  )
}

const CarouselProviderWrapper = styled(CarouselProvider)<{
  bubbleheight: number
  isSmallScreen: boolean
}>({
  [bp.mobile]: {
    marginLeft: '7%',
  },
  [bp.desktop]: {
    marginLeft: '3%',
  },
})

const Wrapper = styled.div({
  paddingTop: 24,
  paddingBottom: 24,

  border: `1px solid ${COLOR.NEUTRAL[300]}`,
  background: COLOR.NEUTRAL[100],
})

interface SliderContainerProps {
  isSmallScreen: boolean
  height: number
}

const SliderContainer = styled.div<SliderContainerProps>(
  {
    flex: 1,
    margin: '0px auto',
    width: '100%',
    [bp.tablet]: {
      width: '100%',
    },
  },
  (props) => ({
    height: props.height,
  }),
)

const SliderWrapper = styled(Slider)({
  alignContent: 'center',
  overflowX: 'hidden',
  height: '100%',
  '*': {
    outlineStyle: 'none !important',
  },
  [bp.mobile]: {
    width: '100%',
    padding: '0px',
  },
  [bp.tablet]: {
    width: '100%',
  },
  [bp.desktop]: {
    width: '100%',
  },
})

export { Testimonials }
