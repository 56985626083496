import type { FC, ComponentProps, KeyboardEvent, ChangeEvent } from 'react'
import React, { useEffect, useRef, useState } from 'react'
import OtpInput from 'react-otp-input'
import { isMobile, isSafari, isIOS } from 'react-device-detect'
import styled from '@emotion/styled'
import { PinInput, PinInputField, Input as ChakraInput } from '@chakra-ui/react'
import { COLOR } from '@extend/zen'
import { bp } from '@helloextend/customers-ui'

interface PasscodeInputProps extends Omit<ComponentProps<typeof PinInputField>, 'onChange'> {
  numberOfInputs: number
  id?: string
  label?: string
  onChange: (passcode: string) => void
  onSubmit: (passcode: string) => void
  isDisabled: boolean
}

const PasscodeInput: FC<PasscodeInputProps> = ({
  numberOfInputs,
  id,
  label,
  value,
  className,
  isDisabled,
  onChange,
  onSubmit,
  ...props
}) => {
  const inputRefs = useRef<Array<HTMLInputElement | null>>([])
  const divRef = useRef<HTMLDivElement | null>(null)
  const [activeInput, setActiveInput] = useState(0)

  useEffect(() => {
    inputRefs.current = inputRefs.current.slice(0, numberOfInputs)
    inputRefs.current[0]?.focus()
  }, [numberOfInputs])

  const getPasscodeValue = (): string[] => {
    return value?.toString().split('') || []
  }

  const handlePasscodeChange = (passcodeValue: string): void => {
    onChange(passcodeValue)

    if (passcodeValue.length === numberOfInputs) {
      onSubmit(passcodeValue)
    }
  }

  /* There is an open issue in the chakra ui repo around auto-filling the
  passcode using PinInput on Safari browsers: https://github.com/chakra-ui/chakra-ui/issues/7142
  The below functions are used to handle Safari browser rendering so that users
  are unable to autofill the code from messages (it doesn't work)
  but are able to copy the code from their messages and paste it. */
  const handlePaste = (event: any): void => {
    handlePasscodeChange(event.clipboardData.getData('text'))
    event.preventDefault()
  }

  const handleChangeSafari = (e: ChangeEvent<HTMLInputElement>): void => {
    const passcodeValue = e.target.value
    changeCodeAtFocusSafari(passcodeValue)
    if (passcodeValue && activeInput < numberOfInputs - 1) {
      focusInputSafari(activeInput + 1)
    }
  }

  const handlePasscodeChangeSafari = (passcode: string[]): void => {
    const passcodeValue: string = passcode.join('')
    onChange(passcodeValue)

    if (passcodeValue.length === numberOfInputs) {
      onSubmit(passcodeValue)
    }
  }

  const handleKeyDownSafari = (e: KeyboardEvent<HTMLInputElement>): void => {
    const passcode = getPasscodeValue()
    switch (e.key) {
      case 'Spacebar':
      case 'Space':
      case 'ArrowUp':
      case 'ArrowDown':
      case 'ArrowRight':
      case 'ArrowLeft':
        e.preventDefault()
        break
      case 'Backspace':
      case 'Delete':
        e.preventDefault()
        if (!passcode[activeInput]) {
          focusInputSafari(activeInput - 1)
          changeCodeAtFocusSafari('', activeInput - 1)
        } else {
          changeCodeAtFocusSafari('')
        }
        break
      case passcode[activeInput]:
        e.preventDefault()
        focusInputSafari(activeInput + 1)
        break
      default:
        break
    }
  }

  const focusInputSafari = (index: number): void => {
    const active = Math.min(numberOfInputs - 1, index)

    if (inputRefs.current[active]) {
      const inputElement = inputRefs.current[active]
      if (inputElement) {
        inputElement.focus()
        setActiveInput(active)
      }
    }
  }

  const changeCodeAtFocusSafari = (passcodeValue: string, focusIndex?: number): void => {
    const [passcodeAtFocus] = passcodeValue.split('')
    const passcode = getPasscodeValue()

    if (focusIndex !== undefined) {
      passcode[focusIndex] = passcodeAtFocus
    } else {
      passcode[activeInput] = passcodeAtFocus
    }

    handlePasscodeChangeSafari(passcode)
  }

  return (
    <>
      <div
        ref={divRef}
        data-cy="passcode-input-form"
        {...{
          className,
          id,
          label,
        }}
      >
        {isMobile && isSafari && isIOS ? (
          <OtpInput
            numInputs={numberOfInputs}
            renderInput={(_props, index) => (
              <ChakraInput
                data-cy={`passcode-input-${index}`}
                key={index}
                id={id}
                {...TextAreaStylesSafari}
                maxLength={1}
                value={getPasscodeValue()[index] ?? ''}
                ref={(element) => {
                  inputRefs.current[index] = element
                }}
                onChange={handleChangeSafari}
                onFocus={() => setActiveInput(index)}
                onKeyDown={handleKeyDownSafari}
                isDisabled={isDisabled}
              />
            )}
            onChange={() => {}}
            onPaste={handlePaste}
            shouldAutoFocus
          />
        ) : (
          <PasscodeWrapper data-cy="passcode-input-wrapper">
            <PinInput
              onChange={handlePasscodeChange}
              defaultValue={value}
              placeholder=""
              otp
              isDisabled={isDisabled}
            >
              {Array.from({ length: numberOfInputs }, (_, index) => index).map((index: number) => {
                return (
                  <PinInputField
                    data-cy={`passcode-input-${index}`}
                    key={index}
                    id={id}
                    {...TextAreaStyles}
                    {...props}
                    ref={(element) => {
                      inputRefs.current[index] = element
                    }}
                    type="number"
                    inputMode="numeric"
                  />
                )
              })}
            </PinInput>
          </PasscodeWrapper>
        )}
      </div>
    </>
  )
}

const PasscodeWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
})

const TextAreaStyles = {
  sx: {
    '&.chakra-pin-input': {
      textAlign: 'center',
      borderRadius: 8,
      border: `1px solid ${COLOR.NEUTRAL[400]}`,
      _focus: {
        border: `2px solid ${COLOR.BLUE[800]}`,
        _invalid: {
          border: `2px solid ${COLOR.RED[700]}`,
        },
      },
      _invalid: {
        border: `1px solid ${COLOR.RED[700]}`,
      },
      width: '48px',
      height: '64px',
      [bp.mobile]: {
        width: '40px',
        margin: '4px',
        fontSize: 24,
      },
      [bp.desktop]: {
        margin: '8px',
        fontSize: 28,
        '&:first-of-type': {
          marginLeft: 0,
        },
        '&:last-of-type': {
          marginRight: 0,
        },
      },
      fontFamily: 'monospace, monospace',
    },
  },
}

const TextAreaStylesSafari = {
  height: 64,
  width: 48,
  borderRadius: 8,
  border: `1px solid ${COLOR.NEUTRAL[400]}`,
  _focus: {
    border: `2px solid ${COLOR.BLUE[800]}`,
    _invalid: {
      border: `2px solid ${COLOR.RED[700]}`,
    },
  },
  _invalid: {
    border: `1px solid ${COLOR.RED[700]}`,
  },
  sx: {
    '&.chakra-input': {
      textAlign: 'center',
      borderRadius: 8,
      border: `1px solid ${COLOR.NEUTRAL[400]}`,
      _focus: {
        border: `2px solid ${COLOR.BLUE[800]}`,
        _invalid: {
          border: `2px solid ${COLOR.RED[700]}`,
        },
      },
      _invalid: {
        border: `1px solid ${COLOR.RED[700]}`,
      },
      padding: 0,
      width: '48px',
      height: '64px',
      [bp.mobile]: {
        width: '40px',
        margin: '4px',
        fontSize: 24,
      },
      [bp.desktop]: {
        margin: '8px',
        fontSize: 28,
      },
      fontFamily: 'monospace, monospace',
    },
  },
}

export type { PasscodeInputProps }
export { PasscodeInput }
