import type { FC } from 'react'
import styled from '@emotion/styled'
import { bp, Card } from '@helloextend/customers-ui'
import { v4 as uuid } from 'uuid'
import { COLOR, Stack } from '@extend/zen'
import { CarouselProvider, Slide, Slider } from 'pure-react-carousel'
import { useScreenSize } from '@helloextend/client-hooks'
import { svgs } from '../../../../lib/assets'

interface ShippingProtectionCoverProps {
  coverageHeader?: string
  coverageItem1Icon?: string
  coverageItem1Title?: string
  coverageItem1Description?: string
  coverageItem2Icon?: string
  coverageItem2Title?: string
  coverageItem2Description?: string
  coverageItem3Icon?: string
  coverageItem3Title?: string
  coverageItem3Description?: string
}

const ShippingProtectionCover: FC<ShippingProtectionCoverProps> = ({
  coverageHeader,
  coverageItem1Icon,
  coverageItem1Title,
  coverageItem1Description,
  coverageItem2Icon,
  coverageItem2Title,
  coverageItem2Description,
  coverageItem3Icon,
  coverageItem3Title,
  coverageItem3Description,
}) => {

  const screenSize = useScreenSize()
  const CARD_WIDTH = 301
  const TOTAL_BUBBLE_HEIGHT = 301
  const BUBBLE_MARGIN_BOTTOM = screenSize === 'medium' ? 69 : 47
  const visibleSlidesCount = (): number => {
    if (screenSize === 'small') return 1
    if (screenSize === 'medium') return 2
    if (screenSize === 'large') return 2
    return 3
  }
  const spacing = 2

  // Draw bubble in Coverage & Benefits section
  // Required: element (image), header, text
  function drawBubble(element: JSX.Element, header: string, text: string) {
    return (
      <ContentDetailWrapper>
        <ContentImgWrapper>{element}</ContentImgWrapper>
        <ContentDetailTextWrapper>
          <Stack align="center" spacing={spacing}>
            <ContentDetailTitle>{header}</ContentDetailTitle>
            <ContentDetailText>{text}</ContentDetailText>
          </Stack>
        </ContentDetailTextWrapper>
      </ContentDetailWrapper>
    )
  }

  // Worry Free Header or bubble 1
  const bubble1Header = coverageItem1Title || 'Worry-free shipping protection'
  const bubble1Text =
    coverageItem1Description ||
    'You’re covered with a replacement in case of loss, damage, or theft.'
  const bubble1IconElement = (coverageItem1Icon && (
    <img src={coverageItem1Icon} alt="package protection" />
  )) || <svgs.WorryFreePackage />
  const bubble1 = drawBubble(bubble1IconElement, bubble1Header, bubble1Text)

  // Hassle Free Header or bubble 2
  const bubble2Header = coverageItem2Title || 'Hassle-free claims'
  const bubble2Text =
    coverageItem2Description ||
    'No deductibles or fees. Simply file a claim with Kaley, our virtual claims assistant.'
  const bubble2IconElement = (
    <ClaimTextBubbles>
      {' '}
      {(coverageItem2Icon && <img src={coverageItem2Icon} alt="two chat bubbles" />) || (
        <svgs.ClaimTextBubbles />
      )}{' '}
    </ClaimTextBubbles>
  )
  const bubble2 = drawBubble(bubble2IconElement, bubble2Header, bubble2Text)

  //  Support Header or bubble 3
  const bubble3Header = coverageItem3Title || 'Convenient support'
  const bubble3Text =
    coverageItem3Description || 'Extend is always happy to help and has online support 24/7.'
  const bubble3IconElement = (
    <ConvenientSupportHeart>
      {' '}
      {(coverageItem3Icon && <img src={coverageItem3Icon} alt="heart" />) || (
        <svgs.ConvenientSupportHeart />
      )}{' '}
    </ConvenientSupportHeart>
  )
  const bubble3 = drawBubble(bubble3IconElement, bubble3Header, bubble3Text)

  const bubbles = [bubble1, bubble2, bubble3]
  const header = coverageHeader || 'Coverage & Benefits'

  return ( 
    <Stack justify="center">
      <ShippingProtectionCoverWrapper>
        <MainTitle>{header}</MainTitle>
        <CarouselProviderWrapper
          visibleSlides={visibleSlidesCount()}
          totalSlides={3}
          step={visibleSlidesCount()}
          naturalSlideWidth={CARD_WIDTH}
          naturalSlideHeight={TOTAL_BUBBLE_HEIGHT + BUBBLE_MARGIN_BOTTOM}
          touchEnabled={true}
          dragEnabled={true}
          isSmallScreen={screenSize === 'small'}
          bubbleheight={TOTAL_BUBBLE_HEIGHT}
        >
          <ContentInfoWrapper>
            <SliderContainer height={400} isSmallScreen={screenSize == 'small'}>
              <SliderWrapper>
                {bubbles.map((entry, i) => {
                  return (
                    <Slide index={i} key={uuid()}>
                      {entry}
                    </Slide>
                  )
                })}
              </SliderWrapper>
            </SliderContainer>
          </ContentInfoWrapper>
        </CarouselProviderWrapper>
      </ShippingProtectionCoverWrapper>
    </Stack>
  )
}

const ShippingProtectionCoverWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: COLOR.BLUE[1000],
  border: `1px solid ${COLOR.NEUTRAL[300]}`,
  paddingTop: '24px',
})

const CarouselProviderWrapper = styled(CarouselProvider)<{
  bubbleheight: number
  isSmallScreen: boolean
}>(
  {
    marginTop: 10,
    width: '100%',
    height: '100%',
    [bp.mobile]: {
      marginLeft: '7%',
    },
    [bp.desktop]: {
      marginLeft: '3%',
    },
  },
  ({ bubbleheight, isSmallScreen }) => ({
    height: isSmallScreen ? 330 : 436,
    '& button': {
      transform: `translateY(-${bubbleheight / 3}px)`,
      [bp.desktop]: {
        transform: `translateY(-${bubbleheight / 2}px)`,
      },
      [bp.desktopMd]: {
        transform: `translateY(-${bubbleheight / 3}px)`,
      },
    },
  }),
)

interface SliderContainerProps {
  isSmallScreen: boolean
  height: number
}

const SliderWrapper = styled(Slider)({
  alignContent: 'center',
  overflowX: 'hidden',
  height: '100%',
  '*': {
    outlineStyle: 'none !important',
  },
  [bp.mobile]: {
    width: '100%',
    padding: '0px 20px',
  },
  [bp.tablet]: {
    width: '100%',
  },
  [bp.desktop]: {
    width: '100%',
  },
})
const SliderContainer = styled.div<SliderContainerProps>(
   {
     flex: 1,
     margin: '0px',
    width: '100%',
    [bp.tablet]: {
      width: '100%',
    },
  },
   (props) => ({
     height: props.height,
   }),
)
const MainTitle = styled.div({
  fontWeight: 700,
  color: COLOR.WHITE,
  marginTop: 8,
  [bp.mobile]: {
    fontSize: 24,
    lineHeight: '28px',
  },
  [bp.desktop]: {
    fontSize: 40,
    lineHeight: '48px',
  },
})

const ClaimTextBubbles = styled.div({
  marginTop: '-16px',
})

const ConvenientSupportHeart = styled.div({
  marginTop: '15px',
})

const ContentInfoWrapper = styled.div({
  marginTop: 10,
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  [bp.mobile]: {
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  [bp.desktop]: {
    gap: 0,
    height: 'auto',
    flexDirection: 'row',
  },
})

const ContentImgWrapper = styled.div({
  [bp.mobile]: {
    marginTop: 0,
    '& svg': {
      height: 57,
      width: 65,
    },
  },
  [bp.desktop]: {
    marginTop: 10,
    '& svg': {
      height: '100%',
      width: '100%',
    },
  },
})

const ContentDetailWrapper = styled(Card)({
  [bp.mobile]: {
    height: 282,
    width: 287,
    padding: '32px 0',
  },
  [bp.desktop]: {
    height: 378,
    width: '90%',
  },
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  border: `1px solid ${COLOR.NEUTRAL[300]}`,
  borderRadius: 24,
  backgroundColor: COLOR.WHITE,
})

const ContentDetailTitle = styled.div({
  fontWeight: 700,
  textAlign: 'center',
  [bp.mobile]: {
    fontSize: 20,
    lineHeignt: '24px',
  },
  [bp.desktop]: {
    fontSize: 24,
    lineHeignt: '32px',
  },
})

const ContentDetailTextWrapper = styled.div({
  [bp.mobile]: {
    marginTop: 16,
  },
  [bp.desktop]: {
    marginTop: 35,
    padding: '2px',
  },
})

const ContentDetailText = styled.div({
  textAlign: 'center',
  maxWidth: '90%',
  margin: '10px auto',
  color: COLOR.NEUTRAL[800],
  [bp.mobile]: {
    fontSize: 16,
    lineHeight: '24px',
  },
  [bp.desktop]: {
    fontSize: 20,
    lineHeight: '28px',
  },
  
})

export { ShippingProtectionCover }
