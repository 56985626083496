import type { FC } from 'react'
import styled from '@emotion/styled'
import { COLOR } from '@extend/zen'
import { bp } from '@helloextend/customers-ui'
import { Stack } from '@extend/zen'
import { isMobile } from 'react-device-detect'
import { useScreenSize } from '@helloextend/client-hooks'
interface LearnMoreProps {
  learnMoreHeader?: string
  learnMoreBody?: string
  learnMoreButtonText?: string
}

const LearnMore: FC<LearnMoreProps> = ({
  learnMoreHeader,
  learnMoreBody,
  learnMoreButtonText,
}) => {
  const handleClick = (): void => {
    window.open('https://www.extend.com/shipping-protection-guide')
  }
  const screenSize = useScreenSize()
  const heading = learnMoreHeader || 'Want to learn more?'
  const body = learnMoreBody || "Read Extend's Guide to Shipping Protection."
  const buttonText = learnMoreButtonText || 'Read Now'
  const spacing = 2
  if (isMobile || screenSize == 'medium' || screenSize == 'small') {
    return (
      <OuterBorderWrapper>
        <Stack align="center" justify="center">
          <MainTitle data-cy="main-title">{heading}</MainTitle>
          <Description>{body}</Description>
          <br />
          <ShopperGuideButton onClick={handleClick}>{buttonText}</ShopperGuideButton>
        </Stack>
      </OuterBorderWrapper>
    )
  } else {
    return (
      <OuterBorderWrapper>
        <Stack isRow align="center" justify="space-between" spacing={spacing}>
          <Stack>
            <MainTitle data-cy="main-title">{heading}</MainTitle>
            <Description>{body}</Description>
          </Stack>
          <Stack align="stretch" spacing={spacing}>
            <ShopperGuideButton onClick={handleClick}>{buttonText}</ShopperGuideButton>
          </Stack>
        </Stack>
      </OuterBorderWrapper>
    )
  }
}

const OuterBorderWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'left',
  backgroundColor: COLOR.BLUE[1000],
  border: `1px solid ${COLOR.NEUTRAL[300]}`,
  borderRadius: 24,
  padding: 20,
  width: '100%',
  [bp.desktop]: {
    padding: '48px 80px',
  },
})

const MainTitle = styled.div({
  fontWeight: 700,
  color: COLOR.WHITE,
  [bp.mobile]: {
    fontSize: 28,
    lineHeight: '38px',
    alignItems: 'center',
  },
  [bp.desktop]: {
    fontSize: 40,
    lineHeight: '54px',
    alignItems: 'left',
    textAlign: 'left',
  },
})

const Description = styled.div({
  textAlign: 'center',
  color: COLOR.WHITE,
  [bp.mobile]: {
    fontSize: 16,
    lineHeight: '24px',
  },
  [bp.desktopMd]: {
    fontSize: 20,
    lineHeight: '28px',
  },
})

const ShopperGuideButton = styled.button({
  backgroundColor: COLOR.WHITE,
  color: COLOR.BLUE[1000],
  border: `1px solid ${COLOR.NEUTRAL[100]}`,
  borderRadius: 6,
  cursor: 'pointer',
  fontSize: 20,
  fontWeight: 700,
  padding: '10px 24px',
  lineHeight: '28px',
  '&:focus': {
    backgroundColor: COLOR.NEUTRAL[200],
    borderColor: COLOR.NEUTRAL[200],
  },
  '&:hover,&:active': {
    borderColor: COLOR.NEUTRAL[200],
    backgroundColor: COLOR.NEUTRAL[200],
  },
})

export { LearnMore }
