export const getDisplayItems = <T>(
  items: T[],
  numberOfItemsToShow: number,
  showMoreThreshold: number,
  isExpanded: boolean,
): T[] => {
  const hasManyItems = items.length > showMoreThreshold

  if (hasManyItems && !isExpanded) {
    if (numberOfItemsToShow <= 0) {
      return []
    } else {
      return items.slice(0, numberOfItemsToShow)
    }
  } else {
    return items
  }
}
