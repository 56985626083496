import type { FC } from 'react'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from '@emotion/styled'
import { COLOR, bp } from '@helloextend/customers-ui'
import { Button, InlineAlert, InlineAlertColor, ModalController } from '@extend/zen'
import { useIntl } from 'react-intl'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { isMobile } from 'react-device-detect'
import {
  useGetMerchantServicingSettingsQuery,
  usePrecheckQuery,
} from '@helloextend/extend-api-rtk-query'
import { useTheme } from '@emotion/react'
import { ConsumerProfileResponse } from '@helloextend/extend-api-rtk-query/src/my-extend/types'
import { useSelector } from 'react-redux'
import { isEmpty } from 'lodash'
import { logEvent } from '../../analytics'
import { LDFlag } from '../../constants/ld-flags'
import { FileClaimLanguageOptionModal } from '../../components/file-claim-language-option-modal/file-claim-language-option-modal'
import {
  getDisplayDetails,
  mapToClaimDetails,
  missingProfileFieldTooltip,
  getValidationErrorMessages,
} from '../../lib/helper-functions'
import * as selectors from '../../reducers/selectors'
import type { ProductDetailsProp } from './contract-details-page'
import ContactMerchantModal from '../../components/contact-merchant-modal'
import type { ContractsSearchIndividual } from '../../types/contract'
import { getCanTransfer } from '../contract-transfer/contract-transfer.utils'
import { ViewAllClaims } from '../claims-page/view-all-claims'
import type { Claim } from '../../types/claim'
import { getAccountInfo } from '../../lib/consumer-profile-utils'

interface ClaimsAndTransferPlanSectionProps {
  contract: ContractsSearchIndividual
  claims: Claim[]
  product: ProductDetailsProp
}

const ClaimsAndTransferPlanSection: FC<ClaimsAndTransferPlanSectionProps> = ({
  contract,
  claims,
  product,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isCustomerSupportModalOpen, setIsCustomerSupportModalOpen] = useState(false)
  const theme = useTheme()
  const { isExtend } = theme.merchantConfiguration
  const history = useHistory()
  const { locale } = useIntl()
  const { [LDFlag.ProfileVerification]: FF_PROFILE_VERIFICATION_ENABLED } = useFlags()
  const { data: merchantServicingSettings } = useGetMerchantServicingSettingsQuery({
    sellerId: contract.sellerId,
  })

  const { data: precheck } = usePrecheckQuery({ contractId: contract.id })

  const consumerProfile = useSelector(selectors.getConsumerProfile)
  const accountInfo = !isEmpty(consumerProfile)
    ? getAccountInfo(consumerProfile as ConsumerProfileResponse)
    : undefined
  const isMissingRequiredProfileField =
    FF_PROFILE_VERIFICATION_ENABLED &&
    !isEmpty(consumerProfile) &&
    (!consumerProfile?.phones ||
      !consumerProfile?.emails ||
      Object.keys(consumerProfile?.phones).length === 0 ||
      Object.keys(consumerProfile?.emails).length === 0)

  const { isClaimFilingDisabled, fileClaimTooltip } = getDisplayDetails({ precheck, contract })
  const { lineItemId } = product

  const lineItems = precheck?.status === 'success' ? precheck?.lineItems : {}
  const shouldDisableClaimFiling =
    !precheck ||
    isMissingRequiredProfileField ||
    isClaimFilingDisabled ||
    (lineItems && lineItems[lineItemId as string]?.hasActiveClaim)
  const toolTipOverride = shouldDisableClaimFiling
    ? getValidationErrorMessages(contract).active_claim_found.default.fileClaimTooltip
    : fileClaimTooltip

  const routeKaley = (): void => {
    logEvent('My Plans - Clicks', 'File a Claim')
    history.push(!isExtend ? `/${locale}/my_claims/file-claim` : `/${locale}/kaley`, {
      contractId: contract.id,
      ...(lineItemId && { lineItemIds: [lineItemId] }),
    })
  }

  const handleModalOpen = (): void => {
    setIsModalOpen(true)
  }

  const handleModalClose = (): void => {
    setIsModalOpen(false)
  }

  const handleFileClaimClick = (): void => {
    if (merchantServicingSettings?.isMerchantOwnedIntake) {
      setIsCustomerSupportModalOpen(true)
    } else if (['fr-CA', 'en-CA'].includes(locale)) {
      handleModalOpen()
    } else {
      routeKaley()
    }
  }

  const handleTransferPlan = (): void => {
    logEvent('My Plans - Contract Card - Clicks', 'Transfer Plan')
    history.push(`/my_plans/${contract.id}/transfer`)
  }

  const handleVerifyClick = (): void => {
    history.push('/profile')
  }

  const claimDetails = mapToClaimDetails(claims as unknown as Claim[], [
    contract as unknown as ContractsSearchIndividual,
  ])

  return (
    <Wrapper data-cy="contract-details-page-file-claim">
      <Header>
        <Title>Claims</Title>
        {FF_PROFILE_VERIFICATION_ENABLED && !!accountInfo && !accountInfo?.email && (
          <InlineAlert
            data-cy="no-email-alert"
            color={InlineAlertColor.red}
            primaryButtonProps={{
              onClick: handleVerifyClick,
              text: 'Verify Now',
              'data-cy': 'verify-email-button',
            }}
          >
            To process your claim, let&apos;s verify your email. We&apos;ll never share your
            information or spam you.
          </InlineAlert>
        )}

        {FF_PROFILE_VERIFICATION_ENABLED && !!accountInfo && !accountInfo?.phoneNumber && (
          <InlineAlert
            data-cy="no-phone-number-alert"
            color={InlineAlertColor.red}
            primaryButtonProps={{
              onClick: handleVerifyClick,
              text: 'Verify Now',
              'data-cy': 'verify-phone-button',
            }}
          >
            To process your claim, let&apos;s verify your phone number. We&apos;ll never share your
            information or spam you.
          </InlineAlert>
        )}
        <ButtonWrapper>
          <StyledButton
            data-cy="contract-details-transfer-plan-button"
            emphasis="medium"
            size={isMobile ? 'regular' : 'large'}
            text="Transfer Plan"
            isDisabled={!getCanTransfer({ contract, claims })}
            onClick={handleTransferPlan}
          />
          <StyledButton
            data-cy="contract-details-file-claim-button"
            emphasis="medium"
            size={isMobile ? 'regular' : 'large'}
            text="File a Claim"
            tooltip={isMissingRequiredProfileField ? missingProfileFieldTooltip : toolTipOverride}
            isDisabled={shouldDisableClaimFiling}
            onClick={handleFileClaimClick}
          />
        </ButtonWrapper>
      </Header>
      <Divider />
      <ViewAllClaims claimDetails={claimDetails} />
      <FileClaimLanguageOptionModal
        isModalOpen={isModalOpen}
        onDismiss={handleModalClose}
        onContinue={routeKaley}
      />
      <ModalController isOpen={isCustomerSupportModalOpen}>
        <ContactMerchantModal
          onDismissRequest={() => setIsCustomerSupportModalOpen(false)}
          contract={contract}
        />
      </ModalController>
    </Wrapper>
  )
}

const Header = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'row',
  alignItems: 'center',
})

const Wrapper = styled.div({
  [bp.mobile]: {
    paddingBottom: 60,
  },
  [bp.desktop]: {
    paddingBottom: 80,
  },
})

const Title = styled.h2({
  color: COLOR.NEUTRAL[1000],
  margin: 0,
  fontWeight: 400,
  [bp.mobile]: {
    fontSize: 20,
    lineHeight: '28px',
  },
  [bp.desktop]: {
    fontSize: 28,
    lineHeight: '36px',
  },
})

const StyledButton = styled(Button)({
  [bp.mobile]: {
    fontSize: 16,
  },
  [bp.desktop]: {
    fontSize: 20,
  },
})

const Divider = styled.div({
  height: 1,
  borderBottom: `1px solid ${COLOR.NEUTRAL[300]}`,
  marginTop: 24,
  marginBottom: 16,
})

const ButtonWrapper = styled.div({
  display: 'flex',
  alignItems: 'flex-end',
  gap: '16px',
})

export { ClaimsAndTransferPlanSection }
