import { ColorGroupKeys, ButtonProps } from '@extend/zen'

export interface CardProps {
  'data-cy': string
  dataProps?: Record<string, string>
  // TODO: [POST-2355] remove override
  extendLiveOverride?: string
  color?: ColorGroupKeys
  // header information
  preHeading?: string | React.ReactNode
  heading: string | React.ReactNode
  subHeading?: string | React.ReactNode
  primaryButtonProps?: ButtonProps
  menuItems?: ButtonProps[]
  // body information
  primaryGroups?: CardGroupItemProps[]
  secondaryGroups?: CardGroupItemProps[]
  groupShowMoreThreshold?: number
  showMoreGroupsText?: string
  showLessGroupsText?: string
  children?: React.ReactNode
}

export interface CardGroupItemProps {
  preHeading?: string | React.ReactNode
  heading?: string | React.ReactNode
  // on the right side of card
  primaryButtonProps?: ButtonProps
  // on the left side of card with header text
  secondaryButtonProps?: ButtonProps
  itemsShowMoreThreshold?: number
  itemsShowMoreText?: string
  itemsShowLessText?: string
  menuItems?: ButtonProps[]
  listItems?: CardListItemProps[]
}

export interface CardListItemProps {
  image?: string
  text: string | React.ReactNode
  subText?: string | React.ReactNode
  // on the right side of card
  primaryButtonProps?: ButtonProps
  // on the left side of card
  secondaryButtonProps?: ButtonProps
  menuItems?: ButtonProps[]
  // TODO: [POST-2355] remove override
  extendLiveOverride?: Partial<Record<keyof CardListItemProps, string>>
}
