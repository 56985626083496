import type { FC } from 'react'
import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import { bp, ContentLayout } from '@helloextend/customers-ui'
import { customLogger } from '@extend/client-helpers'
import {
  getRTKQueryErrorMessage,
  isRtkFetchBaseQueryError,
  useGetCharityContentQuery,
  useGetShippingProtectionContentQuery,
} from '@helloextend/extend-api-rtk-query'
import { SplashScreen } from '@helloextend/component-commons'
import { useFlags } from 'launchdarkly-react-client-sdk'
import * as reactIntl from 'react-intl'
import { useLocation, useParams } from 'react-router'
import { querystring } from '@extend/client-helpers'
import type { OfferType } from '@helloextend/extend-api-client'
import { ResourceErrorBoundary } from '../../../../components/resource-error-boundary'
import { ContentHeader } from './content-header'
import { Faqs } from './faq'
import { ShippingProtectionCover } from './shipping-protection-cover'
import { LearnMore } from './learn-more'
import { WhyExtend } from './why-extend'
import { Testimonials } from './testimonials'
import { HowShippingProtectionWorks } from './how-shipping-protection-works'
import { Charity } from './charity'
import { LDFlag } from '../../../../constants/ld-flags'
import { ShippingProtectionCharityContent } from '../../../../lib/assets'
import { Stack } from '@extend/zen'
import error from '../../../../reducers/auth/error'

const ShippingProtectionPlanDetailsPage: FC = () => {
  const { id: planId } = useParams<{ id?: string }>()
  const { search } = useLocation()
  const { offerType, storeId, charityId, extendProductType } = querystring.parse<{
    offerType?: OfferType
    storeId?: string
    charityId?: string
    extendProductType?: string
  }>(search)

  const { locale } = reactIntl.useIntl()
  const { [LDFlag.ShippingProtectionPlanDetailsCCSFetch]: FF_PLAN_DETAILS_FETCH } = useFlags()
  const isCcsPlanDetailsEnabled = FF_PLAN_DETAILS_FETCH?.ccsPlanDetailsEnabled
  const {
    data: offerData,
    isFetching: offerIsFetching,
    error: offerError,
  } = useGetShippingProtectionContentQuery(
    {
      planId,
      offerType,
      locale,
      storeId,
    },
    { skip: !planId || !isCcsPlanDetailsEnabled },
  )
  const {
    data: charityData,
    isFetching: charityIsFetching,
    error: charityError,
  } = useGetCharityContentQuery(
    {
      charityId,
      storeId,
      extendProductType,
      locale,
    },
    { skip: !charityId },
  )

  useEffect(() => {
    const props = { offerType, storeId, charityId, extendProductType, planId }
    const errorMessage = getRTKQueryErrorMessage(offerError || charityError)

    if (offerError) {
      customLogger.error(
        `[Err: Shipping Protection Page - offer]: ${errorMessage}`,
        isRtkFetchBaseQueryError(offerError)
          ? { status: offerError.status, ...props }
          : { message: offerError.message, ...props },
      )
    }
    if (charityError) {
      customLogger.error(
        `[Err: Shipping Protection Page - charity]: ${errorMessage}`,
        isRtkFetchBaseQueryError(charityError)
          ? { status: charityError.status, ...props }
          : { message: charityError.message, ...props },
      )
    }
  }, [offerError, charityError])

  if (offerIsFetching || charityIsFetching) {
    return (
      <ResourceErrorBoundary>
        <ContentLayout>
          <SplashScreen data-cy="splash-screen" />
        </ContentLayout>
      </ResourceErrorBoundary>
    )
  }

  const offerContentDetails = isCcsPlanDetailsEnabled
    ? offerData?.results?.[0]?.payloads?.shippingProtectionPlanDetails
    : undefined
  const offerFaqDetails = isCcsPlanDetailsEnabled
    ? offerData?.results?.[0]?.payloads?.shippingProtectionPlanDetailsFaqs
    : undefined
  const charityContentDetails = charityId
    ? charityData?.results?.[0]?.payloads.donationConfiguration
    : undefined

  const charityContentEnabled = !!charityId
  const verticalSpacing = 2

  return (
    <StyledContentLayout pageTitle="Shipping Protection Plan Details Page">
      <Stack spacingY={verticalSpacing}>
        <Wrapper>
          <Stack spacingY={verticalSpacing}>
            <ContentHeader {...offerContentDetails} />
            <Divider />
          </Stack>
        </Wrapper>
        <Stack spacingY={verticalSpacing}>
          <ShippingProtectionCover {...offerContentDetails} />
        </Stack>
        <Wrapper>
          {charityContentEnabled ? <Charity {...charityContentDetails} /> : null}
          <Divider />
          <WhyExtend {...offerContentDetails} />
          <Divider />
          <LearnMore {...offerContentDetails} />
          <Divider />
        </Wrapper>
        <Stack spacingY={verticalSpacing}>
          <Testimonials {...offerContentDetails} />
        </Stack>
        <Wrapper>
          <Stack spacingY={verticalSpacing + 1}>
            <HowShippingProtectionWorks {...offerContentDetails} />
            <Divider />
            <Faqs {...offerFaqDetails} />
            {charityId && (
              <CharityDisclosureText>
                {charityContentDetails?.charityPlanDetailsDisclosureText ||
                  ShippingProtectionCharityContent.charityModalDisclosureText}
              </CharityDisclosureText>
            )}
          </Stack>
        </Wrapper>
      </Stack>
    </StyledContentLayout>
  )
}

const StyledContentLayout = styled(ContentLayout)({
  overflowX: 'hidden',
})

const Wrapper = styled.div({
  width: 'inherit',
  [bp.mobile]: {
    padding: '42px 20px 0',
  },
  [bp.desktop]: {
    padding: '42px 120px 0',
    maxWidth: '1440px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
})

const CharityDisclosureText = styled.div({
  textAlign: 'center',
})

const Divider = styled.div({
  [bp.desktop]: {
    paddingBottom: 96,
  },
  [bp.mobile]: {
    paddingBottom: 24,
  },
})

export { ShippingProtectionPlanDetailsPage }
